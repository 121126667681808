// /base
const routes_base = [
  { 
    path: '/download', 
    name:'Download', 
    component: ()=>import('../plugins/Download.vue'), 
    props: route => ({
      download_url: route.query.url,
      download_name: route.query.name,
    }), 
    meta: { isbase: true }, 
  },
  {
    path: '/poster', 
    name: 'Poster', 
    component: ()=>import('../plugins/PosterIndex.vue'), 
    props: route => ({
      poster_sid: route.query.sid,
      poster_year: route.query.year,
      poster_type: route.query.type,
      poster_id: route.query.cardid,
      poster_did: route.query.detailid,
      poster_vid: route.query.id,
      poster_cid: route.query.id,
      poster_scid: route.query.id,
    }), 
    meta: { isbase: true }, 
  }
];

export default routes_base;