const routes = [
  { path: '/2021', redirect: '/2021/home' },
  { path: '/2021/home', name: 'OneHome', component: () => import('@/2021/views/Home.vue'), },
  { path: '/2021/case', name: 'OneCase', component: () => import( /* webpackChunkName: "case" */ '@/2021/views/Case.vue') },
  { path: '/2021/speaker', name: 'OneSpeaker', component: () => import( /* webpackChunkName: "speaker" */ '@/2021/views/Speaker.vue') },
  { path: '/2021/schedule', name: 'OneSchedule', component: () => import( /* webpackChunkName: "schedule" */ '@/2021/views/Schedule.vue') },
  { path: '/2021/schedule_', name: 'OneSchedule_', component: () => import( /* webpackChunkName: "schedule" */ '@/2021/views/Schedule_.vue') },
  { path: '/2021/speech', name: 'OneSpeech', component: () => import( /* webpackChunkName: "speech" */ '@/2021/views/Speech.vue') },
  { path: '/2021/previous', name: 'OnePrevious', component: () => import( /* webpackChunkName: "previous" */ '@/2021/views/Previous.vue') },
  { path: '/2021/teacher', name: 'OneTeacher', component: () => import( /* webpackChunkName: "teacher" */ '@/2021/views/Teacher.vue') },
  { path: '/2021/subject', name: 'OneSubject', component: () => import( /* webpackChunkName: "subject" */ '@/2021/views/Subject.vue') },
  { path: '/2021/course', name: 'OneCourse', component: () => import( /* webpackChunkName: "course" */ '@/2021/views/Course.vue') },
  { path: '/2021/ticket', name: 'OneTicket', component: () => import( /* webpackChunkName: "course" */ '@/2021/views/Ticket.vue') }
]


export default routes