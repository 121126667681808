import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueResource from 'vue-resource'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';


import Toast from './toast.js' //为了引用tip组件
Vue.use(Toast);

import $ from 'jquery'

import 'bootstrap'
// import '@/static/js/bootstrap.min.js'   // 报错
// import '@/static/css/bootstrap.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import 'bootstrap/dist/js/bootstrap.js'

import BootstrapVue from 'bootstrap-vue'
Vue.use(BootstrapVue)

// import style (<= Swiper 5.x)
import 'swiper/css/swiper.css'

import VueRouter from 'vue-router'
const originalPush = VueRouter.prototype.push //获取原型对象上的push函数
VueRouter.prototype.push = function push(location) { //修改原型对象中的push方法
  return originalPush.call(this, location).catch(err => err)
}

import VueLazyLoad from 'vue-lazyload'
Vue.use(VueLazyLoad, {
  preLoad: 1,
  error: require('./assets/img/img_header_blank.png'),
  loading: require('./assets/img/img_header_blank.png'),
  // loading: 'https://mrm.msup.com.cn/Public/imgs/default_user_138_138.png',
  attempt: 2,
})
// Vue.component('vueLazyLoad', VueLazyLoad({
//   preLoad: 1,
//   error: require('./assets/img/mob-logo.png'),
//   loading: require('./assets/img/mob-logo.png'),
//   attempt: 2,
// }));

// 图片点击查看 v-image-preview
import VueDirectiveImagePreviewer from 'vue-directive-image-previewer'
import 'vue-directive-image-previewer/dist/assets/style.css'
Vue.use(VueDirectiveImagePreviewer);

import _ from 'lodash'  // 引入lodash 处理多重嵌套的数据类型

// import $ from 'jquery'
import global from './Global.vue' //设置全局变量
import VueMeta from 'vue-meta'
import {
    Button,
    Input,
    Tabs,
    Pagination,
    Rate,
    Select
  } from 'ant-design-vue';
  import 'ant-design-vue/dist/antd.css';
Vue.prototype.global = global

Vue.config.productionTip = false
Vue.use(VueAwesomeSwiper /* { default options with global component } */ )
Vue.use(VueMeta)

Vue.use(Button);
Vue.use(Input);
Vue.use(Tabs);
Vue.use(Pagination);
Vue.use(Rate)
Vue.use(Select)

Vue.use(ElementUI);
Vue.use(VueResource)
Vue.use(VueRouter)

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')


// 清缓存
const VUE_APP_VERSION = require('../package.json').version
const vers = window.localStorage.getItem("GIAC-version");
if(VUE_APP_VERSION != vers){
  localStorage.clear()
  window.localStorage.setItem("GIAC-version", VUE_APP_VERSION);
  location.reload()
}


//检测浏览器是否为IE8
// var DEFAULT_VERSION = 8.0;
// var ua = navigator.userAgent.toLowerCase();
// var isIE = ua.indexOf("msie") > -1;
// var safariVersion;
// if (isIE) {
//     safariVersion = ua.match(/msie ([\d.]+)/)[1];
// }
// if (safariVersion <= DEFAULT_VERSION) {
//     alert('系统检测到您正在使用ie8以下内核的浏览器，不能实现完美体验，请更换或升级浏览器访问！')
// };

// js文件 s95.cnzz.com
// var cnzz_protocol = (("https:" == document.location.protocol) ? "https://" : "http://");
// document.write(unescape("%3Cspan id='cnzz_stat_icon_3614275'%3E%3C/span%3E%3Cscript src='" + cnzz_protocol + "s95.cnzz.com/stat.php%3Fid%3D3614275' type='text/javascript'%3E%3C/script%3E"));
// // document.write(unescape("%3Cscript type%3D%22text/javascript%22 charset%3D%22utf-8%22 src%3D%22src/common/js/imgscroll.min.js%22%3E %3C/script%3E"));



// // qd share
// function getQueryString(name) {
//     var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
//     var r = window.location.search.substr(1).match(reg);
//     if (r != null) return (r[2]);
//     return null;
// }
// if (getQueryString('qd') != null) {
//     localStorage.setItem('qd', getQueryString('qd'));
// }
// // else{
// // 	localStorage.removeItem('qd')
// // }
// if (getQueryString('share') != null) {
//     localStorage.setItem('share', getQueryString('share'));
// }
// // else{
// // 	localStorage.removeItem('share')
// // }
// function saveChannel() {
//     if (getQueryString('qd') !== null) {
//         const channelObj = JSON.parse(window.localStorage.getItem('giac-channel'))
//         // console.log(channelObj);
//         if (channelObj && channelObj !== null) {
//             if (getQueryString('channel') !== channelObj.channel) {
//                 updateChannel()
//             }
//         } else {
//             updateChannel()
//         }
//     } else {
//         //
//     }
// }
// saveChannel()
// function updateChannel() {
//     const channel = getQueryString('qd');
//     const time = global.getServerTime().getTime()
//     let obj = {
//         channel: channel,
//         timeStamp: time
//     }
//     localStorage.setItem('giac-channel', JSON.stringify(obj))
// }