<template>
  <!-- 大会直播弹窗 -->
  <div v-if="global.popupTimeLive">
    <div class="popup_live" id="popuplive" @click="toclose()" :style="global.popupCloseLive?'top:-100%;':''">
      <div class="pc">
        <div class="popup_table">
          <div class="popup_cell">
            <div class="box" @click="tostop($event)">
              <h3 class="close" @click="toclose()">
                <!-- <i class="glyphicon glyphicon-remove"></i> -->
                <i>×</i>
              </h3>
              <img src="../assets/img/popup_title.png" alt=""/>
              <h1>{{title}}<p>{{title_en}}</p></h1>
              <!-- <h2>-- {{desc}}</h2> -->
              <p><img src="../assets/img/shijian.png" alt="" /> {{start_time}}-{{end_day}} &emsp; <img src="../assets/img/didian.png" alt="" /> {{address}}</p>
              <div @click="tolive()">立即观看直播 > ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="mob">
        <div class="popup_table">
          <div class="popup_cell">
            <div class="box" @click="tostop($event)">
              <h5 class="close" @click="toclose()">
                <!-- <i class="glyphicon glyphicon-remove"></i> -->
                <i>×</i>
              </h5>
              <!-- <img src="../assets/img/logo2022.png" alt=""> -->
              <img src="../assets/img/popup_title_mob.png" alt=""/>
              <!-- <h2>全球互联网架构大会</h2> -->
              <!-- <h3>{{desc}}</h3> -->
              <h1>{{title}}<p>{{title_en}}</p></h1>
              <!-- <h2></h2> -->
              <!-- <h4></h4> -->
              <p>{{start_time}}-{{end_day}}&emsp;{{address}}</p>
              <div @click="tolive()">立即观看直播 > ></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--  -->
    <div class="popup_open_live" 
        :class="[global.popupCloseLive?'is_show':'not_show', global.popupTime?'more':'']"
    >
      <div>
        <!-- <img src="../assets/img/popup_title_mob.png" alt=""/> -->
        <img src="../assets/img/logo2022.png" alt="">
        <h1>{{desc}}</h1>
        <h2>{{title}}</h2>
        <p>{{start_time}}-{{end_day}} {{address}}</p>
        <div @click="toopen()">点击查看</div>
      </div>
    </div>
  </div>
</template>

<script>
import {getMeetingTime, sid} from "@/utils/api.js";

export default {
  name: "Popup",
  data() {
    return {
      title: "全球互联网架构大会",
      title_en: "GLOBAL INTERNET ARCHITECTURE CONFERENCE",
      desc: "",
      start_time: "2022.06.24 09:00",
      end_day: "25",
      end_time: "2022.06.25 17:50",
      address: "",
    }
  },
  created() {
    this.inittime();
  },
  methods: {
    inittime: function() {
      let url = getMeetingTime + sid(this.global.year);
      this.$http
        .jsonp(url)
        .then((data) => {
          return data.json();
        })
        .then((data) => {
          if (data.status === "200") {
            this.address = `${data.data.address}`;
            this.start_time = this.global.formatTime(data.data.startTime, "Y.M.D");
            this.end_day = this.global.formatTime(data.data.endTime, "D");
            this.end_time = this.global.formatTime(data.data.endTime, "Y.M.D");
            let end = this.end_time.slice(0,9) + (parseInt(this.end_time[9])+1).toString();
            let time = new Date();
            time = this.global.formatTime(time.getTime()/1000, "Y.M.D");
            // console.log(time, this.start_time, this.end_time, end);
            this.global.popupCloseLive = (time>=end) ? true : false;
            this.global.popupTimeLive = (time>=this.start_time && time<end) ? true : false;
            // this.global.popupTimeLive = (time<end) ? true : false;
            this.$forceUpdate();
          }
        });
    },
    toopen: function() {
      // 
      this.global.popupCloseLive = false;
      this.$forceUpdate();
    },
    toclose: function() {
      // document.getElementById("popup").style.top = "-100%";
      this.global.popupCloseLive = true;
      this.$forceUpdate();
    },
    tostop: function(e) {
      e.stopPropagation();
    },
    tolive: function() {
      this.$router.push({path:`/${this.global.year}/live_schedule`})
    },
    toform: function() {
      // console.log(this.form_url);
      // console.log(this.global.hashParam);
      let url = 
        this.global.hashParam&&this.global.hashParam["qd"] 
        ? `${this.form_url}&qd=${this.global.hashParam.qd}` 
        : this.form_url;
      window.open(url, "_blank");
    },
  },
}
</script>

<style lang="scss">
.popup_live {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 109;
  position: fixed;
  background-color: rgba(0,0,0,0.5);
  transition: top 0.5s ease;
  * {
    margin: 0;
  }
  .pc, .mob {
    width: 100%;
    height: 100%;
  }
  .popup_table {
    width: 100%;
    height: 100%;
    display: table;
    .popup_cell {
      width: 100%;
      height: 100%;
      display: table-cell;
      vertical-align: middle;
      .box {
        width: 100%;
        margin: 0 auto;
        position: relative;
        background-repeat: no-repeat;
        .close {
          cursor: pointer;
          text-align: center;
          position: absolute;
          border-radius: 50px;
          border: 1px solid rgba(255, 255, 255, 0.8);
          i {
            font-weight: 400;
            font-style: normal;
            color: rgba(255, 255, 255, 0.75);
            display: inline-block;
          }
        }
      }
    }
  }
  .pc .popup_table {
    .popup_cell {
      .box {
        max-width: 750px;
        height: 425px;
        padding: 35px 40px 55px 40px;
        text-align: left;
        background-image: url("../assets/img/popup_bg.png");
        background-position: center;
        position: relative;
        h3 {
          width: 32px;
          height: 32px;
          top: 20px;
          right: 20px;
          padding-left: 2px;
          // i.glyphicon {
          i {
            height: 28px;
            line-height: 28px;
            font-size: 38px;
          }
        }
        h1 {
          width: fit-content;
          // height: 34px;
          // line-height: 34px;
          // font-size: 34px;
          // height: 50px;
          line-height: 50px;
          font-size: 50px;
          font-family: Source Han Sans CN Heavy;
          font-weight: 800;
          color: #FFFFFF;
          margin-top: 60px;
          p {
            width: 100%;
            line-height: 17px;
            font-size: 17px;
            font-family: Alibaba PuHuiTi Regular;
            font-weight: 400;
            color: #FFFFFF;
            margin-top: 8px;
            padding-right: 5px;
            text-transform: uppercase;
            -moz-text-align-last: justify;
            text-align-last: justify;
            letter-spacing: 1px;
          }
        }
        h2 {
          height: 24px;
          line-height: 24px;
          font-size: 24px;
          font-family: Adobe Heiti Std;
          // font-family: Source Han Sans CN Regular;
          // font-weight: 400;
          font-weight: 500;
          color: #FFFFFF;
          margin-top: 15px;
          // margin-top: 20px;
        }
        >p {
          height: 18px;
          line-height: 18px;
          font-size: 16px;
          font-family: Adobe Heiti Std;
          // font-family: Source Han Sans CN Regular;
          // font-weight: 400;
          font-weight: 500;
          color: #FFFFFF;
          // margin-top: 20px;
          margin-top: 35px;
          display: flex;
          flex-wrap: nowrap;
          img {
            height: 18px;
            margin-right: 12px;
          }
        }
        div {
          width: 206px;
          height: 46px;
          line-height: 46px;
          margin-top: 55px;
          font-size: 20px;
          font-family: Source Han Sans CN Medium;
          // font-size: 18px;
          // font-family: Source Han Sans CN Regular;
          // font-weight: 400;
          font-weight: 500;
          color: #3131C6;
          text-align: center;
          background: #FFFFFF;
          border-radius: 23px;
          cursor: pointer;
        }
      }
    }
  }
  .mob .popup_table {
    .popup_cell {
      .box {
        // max-width: 615px;
        // height: 685px;
        max-width: 305px;
        height: 345px;
        padding: 30px 15px;
        text-align: center;
        background-image: url("../assets/img/popup_bg_mob.png");
        background-position: top center;
        background-size: contain;
        h5 {
          width: 25px;
          height: 25px;
          top: 10px;
          right: 10px;
          padding-left: 1px;
          // i.glyphicon {
          i {
            height: 21px;
            line-height: 21px;
            font-size: 34px;
          }
        }
        img {
          height: 25px;
        }
        h1 {
          // height: 40px;
          line-height: 30px;
          font-size: 30px;
          // height: 24px;
          // line-height: 24px;
          // font-size: 24px;
          font-family: Alibaba PuHuiTi Heavy;
          font-weight: 800;
          color: #FFFFFF;
          // color: #3B44B9;
          // background-color: #FFFFFF;
          // margin-top: 35px;
          margin-top: 45px;
          p {
            line-height: 12px;
            font-size: 12px;
            font-family: Alibaba PuHuiTi Regular;
            font-weight: 400;
            color: #FFFFFF;
            text-align: left;
            margin-top: 10px;
          }
        }
        h2 {
          // height: 19px;
          // line-height: 19px;
          // font-size: 19px
          height: 20px;
          line-height: 20px;
          font-size: 20px;
          font-family: Alibaba PuHuiTi Heavy;
          font-weight: 500;
          color: #FFFFFF;
          margin-top: 30px;
        }
        h4 {
          height: 13px;
          line-height: 13px;
          font-size: 13px;
          font-family: Alibaba PuHuiTi Regular;
          font-weight: 500;
          color: #FFFFFF;
          margin-top: 10px;
        }
        h3 {
          width: 80%;
          max-width: 440px;
          margin: 0 auto;
          line-height: 35px;
          font-size: 18px;
          font-family: Alibaba PuHuiTi Regular;
          font-weight: 400;
          color: #FFFFFF;
          border: 1px solid #FFFFFF;
          border-radius: 12px;
        }
        // h2:nth-of-type(1) {
        //   margin-top: 10px;
        //   font-family: Alibaba PuHuiTi Bold;
        //   font-weight: bold;
        // }
        // h2:nth-of-type(2) {
        //   margin-top: 20px;
        //   font-family: Alibaba PuHuiTi Medium;
        //   font-weight: 500;
        // }
        >p {
          // height: 18px;
          // line-height: 18px;
          // font-size: 14px;
          height: 20px;
          line-height: 20px;
          font-size: 18px;
          font-family: Alibaba PuHuiTi Regular;
          font-weight: 500;
          color: #E9E9E9;
          // margin-top: 25px;
          margin-top: 50px;
        }
        div {
          width: 95%;
          height: 44px;
          line-height: 44px;
          margin: 0 auto;
          margin-top: 30px;
          font-size: 18px;
          font-family: Alibaba PuHuiTi Medium;
          // font-weight: 400;
          font-weight: 500;
          color: #3B44B9;
          text-align: center;
          background: rgba(255, 255, 255, 0.8);
          box-shadow: 0px 10px 20px 0px rgba(31, 75, 189, 0.5);
          border-radius: 44px;
          cursor: pointer;
        }
      }
    }
  }
}

.popup_open_live.more {
  bottom: 255px;
}
.popup_open_live {
  width: 180px;
  height: 170px;
  padding: 10px 0;
  z-index: 98;
  // right: -150px;
  bottom: 75px;
  position: fixed;
  border-radius: 6px;
  background: linear-gradient(-45deg, #5da5ff 0%, #2b228d 100%);
  // transition: right 1.25s ease;
  // animation: popup_open 1.25s ease;
  div {
    img {
      width: auto;
      height: 20px;
      // width: 100%;
      // background-color: #2b228d;
    }
    h1 {
      margin-top: 5px;
      font-size: 16px;
      font-family: Source Han Sans CN Medium;
      font-weight: 500;
      color: #FFFFFF;
    }
    h2 {
      // margin-top: 15px;
      // font-size: 24px;
      margin-top: 25px;
      font-size: 18px;
      font-family: Source Han Sans CN Heavy;
      color: #FFFFFF;
    }
    p {
      margin-top: 10px;
      line-height: 12px;
      font-size: 12px;
      font-family: Source Han Sans CN Regular;
      font-weight: 400;
      color: #FFFFFF;
      text-align: center;
    }
    div {
      width: fit-content;
      margin: 0 auto;
      // margin-top: 18px;
      margin-top: 20px;
      padding: 0 15px;
      font-size: 15px;
      font-family: Source Han Sans CN Regular;
      color: #FFFFFF;
      border-radius: 3px;
      border: 1px solid rgba(255, 255, 255, 0.5);
      background-color: rgba(255, 255, 255, 0.1);
      cursor: pointer;
    }
  }
}
.is_show {
  right: 2px;
  animation: popup_close 1.25s ease;
}
.not_show {
  right: -180px;
  animation: popup_open 0.5s ease;
}
@keyframes popup_close {
  0% {right: -180px;}
  40% {right: -180px;}
  100% {right: 2px;}
}
@keyframes popup_open {
  0% {right: 2px;}
  100% {right: -180px;}
}

.pc {
  display: block;
}
.mob {
  display: none;
}
@media (max-width: 767px) {
  .pc {
    display: none;
  }
  .mob {
    display: block;
  }
}
</style>