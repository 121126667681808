var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[(!(_vm.$route.meta && _vm.$route.meta.isbase))?_vm._t("default",function(){return [(
        false && 
        _vm.isNew() && 
        _vm.$route.name.indexOf('Live') == -1 && 
        _vm.$route.name.indexOf('UserCenter') == -1 && 
        _vm.$route.name.indexOf('Login') == -1 && 
        _vm.$route.name.indexOf('Ticket') == -1 && 
        _vm.$route.name.indexOf('Order') == -1 
      )?_c('Popup'):_vm._e(),(
        false && 
        _vm.isNew() && 
        _vm.$route.name.indexOf('LiveSchedule') == -1 && 
        _vm.$route.name.indexOf('Live') == -1 && 
        _vm.$route.name.indexOf('UserCenter') == -1 && 
        _vm.$route.name.indexOf('Login') == -1 && 
        _vm.$route.name.indexOf('Ticket') == -1 && 
        _vm.$route.name.indexOf('Order') == -1 
      )?_c('popup-live'):_vm._e(),(_vm.$route.name && _vm.$route.name.indexOf('One') > -1)?_c('SevenHeader'):_vm._e(),(_vm.isNew())?_c('Header'):_vm._e()]}):_vm._e(),_c('router-view',{staticClass:"main-page"}),(!(_vm.$route.meta && _vm.$route.meta.isbase))?_vm._t("default",function(){return [_c('Footer',{key:'giac_footer'+_vm.showFooter}),(_vm.$route.name && _vm.$route.name.indexOf('One') > -1)?_c('SevenBottom'):_vm._e(),(
        _vm.isNew() &&
        _vm.$route.name.indexOf('Live') == -1 && 
        _vm.$route.name.indexOf('Ticket') == -1 && 
        _vm.$route.name.indexOf('Order') == -1 
      )?_c('Bottom'):_vm._e()]}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }