<template>
  <div class="bottom">
    <div class="bottompc">
      <div class="item">
        <span>联系顾问，咨询详情</span>
        <button @click="toNext(1)">峰会咨询</button>
      </div>
      <div class="item">
        <!-- <span>使用推荐码报名，享5.5折优惠</span> -->
        <!-- <button @click="toNext(2)">推荐码报名</button> -->
        <span>即将开幕，抢购门票</span>
        <!-- <button @click="toNext(2)">立即报名</button> -->
        <button>已售罄</button>
      </div>
      <!-- <div class="item">
     <span>限时优惠，点击抢票</span>
     <button @click="toNext(2)">领取折扣</button>
    </div> -->
    </div>
    <div class="bottommob">
      <div class="item" @click="toNext(1)">内容咨询</div>
      <!-- <div class="item" @click="toNext(2)">推荐码报名</div> -->
      <!-- <div class="item" @click="toNext(2)">立即报名</div> -->
      <div class="item">已售罄</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Bottom",
  methods: {
    toNext: function (n) {
      let hash = "";
      hash = this.global.hashParam["qd"];
      if (n == 1) {
        // window.open('http://pr.msup.cn/j45l71','_blank')
        if (hash) {
          window.open(
            "http://acrm.msup.com.cn/form/detail?form_id=14&qd=" + hash,
            "_blank"
          );
        } else {
          window.open(
            "http://acrm.msup.com.cn/form/detail?form_id=14",
            "_blank"
          );
        }
      } else if (n == 2) {
        this.$router.push({ path: "/ticket" });
        // window.open('http://pr.msup.cn/L2YfU2','_blank')
        // if(hash){
        //   window.open('http://acrm.msup.com.cn/form/detail?form_id=23&qd='+hash,'_blank')
        // }else{
        //   window.open('http://acrm.msup.com.cn/form/detail?form_id=23','_blank')
        // }
      }
    },
  },
};
</script>

<style>
.bottom .bottompc {
  width: 100%;
  height: 68px;
  padding: 14px 0;
  background: rgba(0, 0, 0, 0.3);
  display: inline-flex;
  flex-wrap: nowrap;
  position: fixed;
  bottom: 0;
  left: 0;
}
.item {
  width: 50%;
  line-height: 40px;
  font-size: 18px;
  font-family: Source Han Sans CN Regular;
  font-weight: 500;
  color: #ffffff;
}
.bottompc .item:nth-of-type(1) {
  text-align: right;
  padding-right: 35px;
}
.bottompc .item:nth-of-type(2) {
  text-align: left;
  padding-left: 35px;
}
.item button {
  width: 150px;
  margin-left: 20px;
  background: #003177;
  border-radius: 20px;
  border: none;
}

.bottom .bottommob {
  width: 100%;
  display: none;
  flex-wrap: nowrap;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
}
.bottommob .item {
  line-height: 50px;
  font-size: 16px;
  /* font-family: PingFang SC;
  font-weight: 500; */
}
.bottommob .item:nth-of-type(1) {
  background: #f6f9ff;
  border: 1px solid #003177;
  color: #293f76;
}
.bottommob .item:nth-of-type(2) {
  background: #293f76;
  border: 1px solid #003177;
  color: #ffffff;
}
/* @media (max-width: 800px) { */
@media (max-width: 767px) {
  .bottompc {
    display: none !important;
  }
  .bottommob {
    display: inline-flex !important;
  }
}
</style>