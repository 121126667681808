// TenRoutes
const routes_2023_sz = [
  { path: '/2023sz/home', name: 'ThreeSZHome', component: () => import( '../views2023_sz/Home.vue') },
  { path: '/2023sz/case', name: 'ThreeSZCase', component: () => import( '../views2023_sz/Case.vue') },
  { path: '/2023sz/speaker', name: 'ThreeSZSpeaker', component: () => import( '../views2023_sz/Speaker.vue' /* webpackChunkName: "speaker" */ ) },
  { path: '/2023sz/speaker_', name: 'ThreeSZSpeaker_', component: () => import( '../views2023_sz/Speaker_.vue' /* webpackChunkName: "speaker" */ ) },
  { path: '/2023sz/live', name: 'ThreeSZLive', component: () => import( '../views2023_sz/Live.vue' /* webpackChunkName: "schedule" */ ) },
  { path: '/2023sz/live_schedule', name: 'ThreeSZLiveSchedule', component: () => import( '../views2023_sz/LiveSchedule.vue' ) },
  { path: '/2023sz/schedule', name: 'ThreeSZSchedule', component: () => import( '../views2023_sz/Schedule.vue') },
  { path: '/2023sz/speech', name: 'ThreeSZSpeech', component: () => import( '../views2023_sz/Speech.vue' /* webpackChunkName: "speech" */ ) },
  { path: '/2023sz/previous', name: 'ThreeSZPrevious', component: () => import( '../views2023_sz/Previous.vue' /* webpackChunkName: "previous" */ ) },
  { path: '/2023sz/teacher', name: 'ThreeSZTeacher', component: () => import( '../views2023_sz/Teacher.vue' /* webpackChunkName: "teacher" */ ) },
  { path: '/2023sz/subject', name: 'ThreeSZSubject', component: () => import( '../views2023_sz/Subject.vue' /* webpackChunkName: "subject" */ ) },
  { path: '/2023sz/course', name: 'ThreeSZCourse', component: () => import( '../views2023_sz/Course.vue' /* webpackChunkName: "course" */ ) },
  { path: '/2023sz/ticket', name: 'ThreeSZTicket', component: () => import( '../views2023_sz/Ticket.vue' ), },
  { path: '/2023sz/order', name: 'ThreeSZOrder', component: () => import( '../views2023_sz/Order.vue' ), },
  { path: '/2023sz/login', name: 'ThreeSZLogin', component: () => import( '../views2023_sz/Login.vue' ), },
  { path: '/2023sz/userCenter', name: 'ThreeSZUserCenter', component: () => import( '../views2023_sz/UserCenter.vue' ), },
]

export default routes_2023_sz;