// TenRoutes
const routes_2024_sz = [
  { path: '/2024sz/home', name: 'FourSZHome', component: () => import( '../views2024_sz/Home.vue') },
  { path: '/2024sz/case', name: 'FourSZCase', component: () => import( '../views2024_sz/Case.vue') },
  { path: '/2024sz/speaker', name: 'FourSZSpeaker', component: () => import( '../views2024_sz/Speaker.vue' /* webpackChunkName: "speaker" */ ) },
  { path: '/2024sz/speaker_', name: 'FourSZSpeaker_', component: () => import( '../views2024_sz/Speaker_.vue' /* webpackChunkName: "speaker" */ ) },
  { path: '/2024sz/live', name: 'FourSZLive', component: () => import( '../views2024_sz/Live.vue' /* webpackChunkName: "schedule" */ ) },
  { path: '/2024sz/live_schedule', name: 'FourSZLiveSchedule', component: () => import( '../views2024_sz/LiveSchedule.vue' ) },
  { path: '/2024sz/schedule', name: 'FourSZSchedule', component: () => import( '../views2024_sz/Schedule.vue') },
  { path: '/2024sz/schedule_', name: 'FourSZSchedule_', component: () => import( '../views2024_sz/Schedule__.vue') },
  { path: '/2024sz/speech', name: 'FourSZSpeech', component: () => import( '../views2024_sz/Speech.vue' /* webpackChunkName: "speech" */ ) },
  { path: '/2024sz/previous', name: 'FourSZPrevious', component: () => import( '../views2024_sz/Previous.vue' /* webpackChunkName: "previous" */ ) },
  { path: '/2024sz/teacher', name: 'FourSZTeacher', component: () => import( '../views2024_sz/Teacher.vue' /* webpackChunkName: "teacher" */ ) },
  { path: '/2024sz/subject', name: 'FourSZSubject', component: () => import( '../views2024_sz/Subject.vue' /* webpackChunkName: "subject" */ ) },
  { path: '/2024sz/course', name: 'FourSZCourse', component: () => import( '../views2024_sz/Course.vue' /* webpackChunkName: "course" */ ) },
  { path: '/2024sz/ticket', name: 'FourSZTicket', component: () => import( '../views2024_sz/Ticket.vue' ), },
  { path: '/2024sz/order', name: 'FourSZOrder', component: () => import( '../views2024_sz/Order.vue' ), },
  { path: '/2024sz/login', name: 'FourSZLogin', component: () => import( '../views2024_sz/Login.vue' ), },
  { path: '/2024sz/userCenter', name: 'FourSZUserCenter', component: () => import( '../views2024_sz/UserCenter.vue' ), },
]

export default routes_2024_sz;