<template>  
  <!-- 全局提示框 -->
  <div v-show="visible" class="dialog-tips dialog-center">
    <div class="tip">
      <!-- <Icon v-if="hint" :_type="hint" :_size="14" :_color="'#FFFFFF'"></Icon> -->
      {{message}}
    </div>
  </div>
</template>

<script>
export default {
  name:"ToastComponent",
  components: {
    Icon: () => import('./Icon.vue'),
  },
  data() {
    return {
      visible: false,
      message: "",
      hint: "", // success、error、warning、info、
    };
  }
};
</script>

<style>
.dialog-tips {
  position: fixed;
  z-index: 10000000000;
  min-width: 220px;
  padding: 20px 22px;
  white-space: nowrap;
  background-color: #000000;
  box-shadow: 0px 8px 15px 0 rgba(0, 0, 0, 0.1);
	opacity: .8;
  text-align: center;
}
.dialog-tips .dialog-tips-icon {
	width: 54px;
	height: 54px;
	@extend %bg-contain;
	display: inline-block;
	margin-bottom: 13px;
}
.dialog-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)
}
.tip {
	color: #FFFFFF;
	font-weight: bold;
  text-align: center;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}
.tip .icon-defined {
  margin-right: 10px;
}
</style>