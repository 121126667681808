<template>
  <div id="app">
    <slot v-if="!($route.meta && $route.meta.isbase)">
      <!-- 直播弹窗（沙龙） -->
      <Popup v-if="
          false && 
          isNew() && 
          $route.name.indexOf('Live') == -1 && 
          $route.name.indexOf('UserCenter') == -1 && 
          $route.name.indexOf('Login') == -1 && 
          $route.name.indexOf('Ticket') == -1 && 
          $route.name.indexOf('Order') == -1 
        "
      ></Popup>
      <!-- 直播弹窗（大会） -->
      <popup-live 
        v-if="
          false && 
          isNew() && 
          $route.name.indexOf('LiveSchedule') == -1 && 
          $route.name.indexOf('Live') == -1 && 
          $route.name.indexOf('UserCenter') == -1 && 
          $route.name.indexOf('Login') == -1 && 
          $route.name.indexOf('Ticket') == -1 && 
          $route.name.indexOf('Order') == -1 
        "
      ></popup-live>
      <!-- 2021及之后的年份 -->
      <SevenHeader v-if="$route.name && $route.name.indexOf('One') > -1"></SevenHeader>
      <Header v-if="isNew()"></Header>
    </slot>
    <router-view class="main-page" />
    <slot v-if="!($route.meta && $route.meta.isbase)">
      <Footer :key="'giac_footer'+showFooter"></Footer>
      <!-- 2021及之后的年份 -->
      <SevenBottom v-if="$route.name && $route.name.indexOf('One') > -1"></SevenBottom>
      <Bottom 
        v-if="
          isNew() &&
          $route.name.indexOf('Live') == -1 && 
          $route.name.indexOf('Ticket') == -1 && 
          $route.name.indexOf('Order') == -1 
        "
      ></Bottom>
    </slot>
  </div>
</template>

<script>
// 引入 2021 header bottom footer
import SevenHeader from "@/2021/components/Header.vue";
import SevenBottom from "@/2021/components/Bottom.vue";
// import SevenFooter from "@/2021/components/Footer.vue";

import Header from "@/components/Header.vue";
import Bottom from "@/components/Bottom.vue";
import Footer from "@/components/Footer.vue";

import Popup from "@/components/Popup.vue";
import PopupLive from "@/components/PopupLive.vue";

import {id_arr} from "@/utils/api.js";

export default {
  data() {
    return {
      showFooter: 0,
    }
  },
  components: {
    SevenHeader,
    SevenBottom,
    Header,
    Bottom,
    Footer,
    Popup,
    PopupLive,
  },
  created() {
    this.global.getHash();
    // console.log(this.$route, window.location);
    // 排除登录页回转的情况
    // (!this.$route.path||this.$route.path==''||this.$route.path=='/') && 
    // window.location.pathname.indexOf("/login")>-1 
    // ? "" : this.getYear(this.$route);
    // this.getYear(window.location,'l');
    // console.log(id_arr);
    // this.setArea(window.location,'l');
    // console.log(window.location.pathname);
    this.global.year = 
      window.location.pathname.split('/').length>=3 
      ? window.location.pathname.split("/")[1] 
      : this.global.year;
  },
  watch: {
    $route(to, from) {
      // console.log("$route");
      // console.log(to, from);
      // console.log(to.path.split('/')[1]);
      // if(from.path && from.path!='' && from.path!='/' && to.path.split('/')[1]!=from.path.split('/')[1]) {
      //   this.getYear(to);
      // }
      // if(from.name){
      //   // this.setArea(to);
      // }
      if(to.name!=from.name && to.path.split('/').length>=3){
        this.global.year = to.path.split('/')[1];
        if(from.path.split('/')[1] != to.path.split('/')[1]){
          this.showFooter ++;
        }
      }
    },
  },
  methods: {
    // 新旧版本
    isNew: function() {
      let flag = 
        this.$route.name && (
          // this.$route.name.indexOf('Two') > -1 ||
          // this.$route.name.indexOf('Three') > -1 || 
          // this.$route.name.indexOf('Four') > -1
          this.$route.name.indexOf('One') == -1
        ) || false;
      return flag;
    },

    // 暂弃用，改用路由守卫

    setArea: function(base, type) {
      // console.log(id_arr);
      let arr = id_arr;
      let years = [];
      let path, year;
      for(let i=0; i<Object.keys(arr).length; i++) {
        years.push(Object.keys(arr)[i].toString());
      }
      path = type=='l' ? base.pathname : base.path;
      year = path.split('/')[1].toString();
      // console.log("》》》 ", type, arr, base);
      // console.log("》》》 ", years);
      // console.log("》》》 ", year, typeof year);
      // return;

      // 已重定向'/'至'/2022sz' 
      // 已重定向'/2022sz'至'/2022sz/home'，已重定向'/2022sh'至'/2022sh/home'，已重定向'/2021'至'/2021/home'
      // 重定向诸如 '/home' 的链接至对应最新页
      if(path.split('/').length>=2 && years.indexOf(year)==-1){
        this.global.year = this.global.year=="" ? years[0] : this.global.year;
        if(type == 'l'){
          // console.log("》》 l1");
          this.$router.push(`/${this.global.year}${path}${base.search}`);
        } else {
          // console.log("》》 r1");
          this.$router.push({path:`/${this.global.year}${path}`, query:base.query});
        }
      } else {
        this.global.year = year;
      }
      // console.log("App.vue 👌🏻");
    },

    getYear(base, type) {
      // console.log(base);
      if(type == "l"){
        // base.pathname!='' && base.pathname!='/'
        if(!this.checkYear(base.pathname.split('/')[1])){
          this.$router.push(`/${this.global.year}/${base.pathname.slice(1)}`);
        } else {
          this.global.year = base.pathname.split('/')[1];
        }
        return;
      }
      // console.log(this.checkYear(base.path.split('/')[1]));
      if( ((!base.path) || (base.path=='') || (base.path=='/')) && 
          !window.localStorage.getItem("linkurl") ){
        this.$router.push({
          path: `/${this.global.year}/home${window.location.search}`,
        });
      } else if( base.path && base.path.split('/')[1] && this.checkYear(base.path.split('/')[1]) ){
        this.global.year = base.path.split('/')[1];
      } else {
        this.checkHref(base);
      }
    },
    checkYear(path) {
      let res = ((path.indexOf('2022')==-1) && (path.indexOf('2021')==-1));
      return !res;
    },
    checkHref(base) {
      // !this.checkYear(base.path.split('/')[1])   base=this.$route / base=to
      let query = {...base.query};
      let len = base.path.split('/').length;
      let path = "";
      path = `/${this.global.year}`;
      if(base.path.split('/')[len-1]==''){
        path += base.path.slice(0,-1);
      } else {
        path += base.path;
      }
      // console.log(base.fullPath, path, query);
      this.$router.push({
        path: path,
        query: query
      });
    },
  },
};
</script>

<style lang="scss">
/* font */

// @font-face {
//   font-family: "PingFang SC";
//   src: url("./assets/fonts/PingFang Regular.ttf");
// }
// @font-face {
//   font-family: "Alibaba PuHuiTi";
//   src: url("./assets/fonts/Alibaba-PuHuiTi-Medium.otf");
// }
// @font-face {
//   font-family: "Source Han Sans CN";
//   src: url("./assets/fonts/SourceHanSansCN-Medium.otf");
// }
// @font-face {
//   font-family: Arial;
//   src: url("./assets/fonts/Arial.ttf");
// }

@font-face {
  font-family: Arial;
  src: url("https://f.msup.com.cn/Arial.ttf");
}
// 思源 Source Han Sans CN
@font-face {
  font-family: "Source Han Sans CN";
  src: url("https://f.msup.com.cn/fonts/giac/SourceHanSansCN-Medium.ttf");
  // src: url("https://f.msup.com.cn/SourceHanSansCN-Medium.ttf");
}
@font-face {
  font-family: "Source Han Sans CN Medium";
  src: url("https://f.msup.com.cn/fonts/giac/SourceHanSansCN-Medium.ttf");
  // src: url("https://f.msup.com.cn/SourceHanSansCN-Medium.ttf");
}
@font-face {
  font-family: "Source Han Sans CN Regular";
  src: url("https://f.msup.com.cn/SourceHanSansCN-Regular.ttf");
}
@font-face {
  font-family: "Source Han Sans CN Bold";
  src: url("https://f.msup.com.cn/SOURCEHANSANSCN-BOLD.ttf");
}
@font-face {
  font-family: "Source Han Sans CN Light";
  src: url("https://f.msup.com.cn/SourceHanSansCN-Light.ttf");
}
// 压缩   Alibaba PuHuiTi
@font-face {
  font-family: "Alibaba PuHuiTi";
  src: url("https://f.msup.com.cn/fonts/giac/Alibaba-PuHuiTi-Medium.ttf");
  // src: url("https://f.msup.com.cn/Alibaba-PuHuiTi-Medium.ttf");
}
@font-face {
  font-family: "Alibaba PuHuiTi Medium";
  src: url("https://f.msup.com.cn/fonts/giac/Alibaba-PuHuiTi-Medium.ttf");
  // src: url("https://f.msup.com.cn/Alibaba-PuHuiTi-Medium.ttf");
}
@font-face {
  font-family: "Alibaba PuHuiTi Bold";
  src: url("https://f.msup.com.cn/Alibaba-PuHuiTi-Bold.ttf");
}
@font-face {
  font-family: "Alibaba PuHuiTi Regular";
  src: url("https://f.msup.com.cn/fonts/giac/Alibaba-PuHuiTi-Regular.ttf");
  // src: url("https://f.msup.com.cn/Alibaba-PuHuiTi-Regular.ttf");
}
@font-face {
  font-family: "Alibaba PuHuiTi Heavy";
  src: url("https://f.msup.com.cn/Alibaba-PuHuiTi-Heavy.ttf");
}
// 压缩   PingFang SC
@font-face {
  font-family: "PingFang SC Semibold";
  src: url("https://f.msup.com.cn/PingFang SC Semibold.ttf");
}
@font-face {
  font-family: "PingFang SC Medium";
  src: url("https://f.msup.com.cn/PingFang%20SC%20Medium.ttf");
  // src: url("https://f.msup.com.cn/PingFangSCMedium.ttf");
}
@font-face {
  font-family: "PingFang SC Light";
  src: url("https://f.msup.com.cn/PingFang SC Light.ttf");
}
@font-face {
  font-family: "PingFang SC Regular";
  src: url("https://f.msup.com.cn/PingFangSCRegular.ttf");
}
@font-face {
  font-family: "PingFang SC";
  src: url("https://f.msup.com.cn/PingFangSCRegular.ttf");
}

</style>

<style lang="scss">
@import "./assets/css/bootstrap.min.css";

body {
  max-width: 100%!important;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a, a:hover, a:visited {
  text-decoration: none!important;
  color: #000000;
}
button {
  cursor: pointer;
}
h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  margin-top: 0;
  margin-bottom: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  position: relative;
  // 
  --main-bgcolor: #003177;
  --deep-color: #003177;
  li {
    list-style: none;
  }
  .main-page {
    min-height: calc(100vh - 150px - 550px);
    margin-top: 152px;
  }
  @media (max-width:767px) {
    .main-page {
      margin-top: 54px;
      min-height: calc(100vh - 55px - 510px);
    }
  }
}

#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
    text-decoration: none;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
  button:hover {
    opacity: 0.8;
  }
}

.isblank {
  margin: 0;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  color: #2630B2;
}
// @media (max-width: 1023px) {
// @media (max-width: 767px) {
@media (max-width: 843px) {
  .isblank {
    font-size: 20px;
  }
}
@media (max-width: 750px) {
  .isblank {
    font-size: 16px;
  }
}
</style>
