// NineRoutes
const routes_2022_sh = [
  { path: '/2022sh/home', name: 'TwoSHHome', component: () => import( '../views2022_sh/Home.vue') },
  { path: '/2022sh/home_', name: 'TwoSHHome_', component: () => import( '../views2022_sh/Home_.vue') },
  { path: '/2022sh/case', name: 'TwoSHCase', component: () => import( '../views2022_sh/Case.vue') },
  { path: '/2022sh/speaker', name: 'TwoSHSpeaker', component: () => import( '../views2022_sh/Speaker.vue' /* webpackChunkName: "speaker" */ ) },
  { path: '/2022sh/speaker_', name: 'TwoSHSpeaker_', component: () => import( '../views2022_sh/Speaker_.vue' /* webpackChunkName: "speaker" */ ) },
  { path: '/2022sh/live', name: 'TwoSHLive', component: () => import( '../views2022_sh/Live.vue' /* webpackChunkName: "schedule" */ ) },
  { path: '/2022sh/live_schedule', name: 'TwoSHLiveSchedule', component: () => import( '../views2022_sh/LiveSchedule.vue' ) },
  { path: '/2022sh/schedule', name: 'TwoSHSchedule', component: () => import( '../views2022_sh/Schedule.vue') },
  { path: '/2022sh/oldSchedule', name: 'TwoSHOldSchedule', component: () => import( '../views2022_sh/OldSchedule.vue' ) },
  { path: '/2022sh/speech', name: 'TwoSHSpeech', component: () => import( '../views2022_sh/Speech.vue' /* webpackChunkName: "speech" */ ) },
  { path: '/2022sh/previous', name: 'TwoSHPrevious', component: () => import( '../views2022_sh/Previous.vue' /* webpackChunkName: "previous" */ ) },
  { path: '/2022sh/teacher', name: 'TwoSHTeacher', component: () => import( '../views2022_sh/Teacher.vue' /* webpackChunkName: "teacher" */ ) },
  { path: '/2022sh/subject', name: 'TwoSHSubject', component: () => import( '../views2022_sh/Subject.vue' /* webpackChunkName: "subject" */ ) },
  { path: '/2022sh/course', name: 'TwoSHCourse', component: () => import( '../views2022_sh/Course.vue' /* webpackChunkName: "course" */ ) },
  { path: '/2022sh/ticket', name: 'TwoSHTicket', component: () => import( '../views2022_sh/Ticket.vue' ), },
  { path: '/2022sh/order', name: 'TwoSHOrder', component: () => import( '../views2022_sh/Order.vue' ), },
  { path: '/2022sh/login', name: 'TwoSHLogin', component: () => import( '../views2022_sh/Login.vue' ), },
  { path: '/2022sh/userCenter', name: 'TwoSHUserCenter', component: () => import( '../views2022_sh/UserCenter.vue' ), },
]

export default routes_2022_sh;