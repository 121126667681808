<template>
  <div class="nav-wrapper" ref="navWrapper">
    <slot v-if="headerInit">
      <div class="pc-wrapper pc" ref="navPc">
        <div class="login-wrapper">
          <div class="login">
            <div class="login-left">
              <p>您好，欢迎来到 GIAC 全球互联网架构大会</p>
              <div class="login-button" v-if="!logined">
                <span @click="login()">登录</span>
                <div>/</div>
                <span class="register" @click="register">注册</span>
              </div>
              <slot v-else>
                <!-- <span class="quit" @click="quit">退出</span> -->
                <span>{{ userName }}</span>
              </slot>
            </div>
            <div class="login-right" v-if="logined">
              <!-- <a @click="openVip" v-if="!isv">成为会员</a> -->
              <a @click="goUserCenter()">用户中心</a>
              <a @click="quit">退出</a>
            </div>
          </div>
        </div>
        <div class="nav">
          <router-link class="logo-wrapper" :to="`/${global.year}/home`">
            <img src="../assets/img/header-logo.png" alt="" />
          </router-link>
          <el-row class="nav-bar">
            <router-link class="nav-item" :to="`/${global.year}/home`">首页</router-link>
            <router-link class="nav-item" :to="`/${global.year}/live_schedule`" v-if="hasLive">大会直播</router-link>
            <router-link class="nav-item" :to="`/${global.year}/case`">精选案例</router-link>
            <router-link class="nav-item" :to="`/${global.year}/schedule`">日程安排</router-link>
            <router-link class="nav-item" :to="`/${global.year}/speaker`">演讲嘉宾</router-link>
            <el-dropdown class="nav-item" trigger="click">
              <span class="el-dropdown-link">
                往期回顾<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-if="global.year!='2023sz' && global.year.slice(0,4)>'2022'">
                  <a href="/2023sz" target="_blank">
                    2023深圳
                  </a>
                </el-dropdown-item>
                <el-dropdown-item v-if="global.year!='2022sh'">
                  <a href="/2022sh" target="_blank">
                    2022上海
                  </a>
                </el-dropdown-item>
                <el-dropdown-item v-if="global.year!='2022sz'">
                  <a href="/2022sz" target="_blank">
                    2022深圳
                  </a>
                </el-dropdown-item>
                <el-dropdown-item>
                  <!-- https://giac.msup.com.cn -->
                  <a href="/2021/home" target="_blank">
                    2021深圳
                  </a>
                </el-dropdown-item>
                <el-dropdown-item>
                  <a href="http://giac-history.msup.com.cn/Giac2020/default/index" target="_blank">
                    2020深圳
                  </a>
                </el-dropdown-item>
                <el-dropdown-item>
                  <a href="http://giac-history.msup.com.cn/2019" target="_blank">
                    2019深圳
                  </a>
                </el-dropdown-item>
                <el-dropdown-item>
                  <a href="http://giac-history.msup.com.cn/sh2018/index.html" target="_blank">
                    2018上海
                  </a>
                </el-dropdown-item>
                <el-dropdown-item>
                  <a href="http://giac-history.msup.com.cn/sz2018/index.html" target="_blank">
                    2018深圳
                  </a>
                </el-dropdown-item>
                <el-dropdown-item>
                  <a href="http://giac-history.msup.com.cn/sh2017/index.html" target="_blank">
                    2017上海
                  </a>
                </el-dropdown-item>
                <el-dropdown-item>
                  <a href="http://giac-history.msup.com.cn/bj2016/index.html" target="_blank">
                    2016北京
                  </a>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <router-link class="nav-item" v-if="['2023sz','2024sz'].includes(global.year)" :to="`/${global.year}/home?loc=sponsor`">
              赞助伙伴
            </router-link>
            <a class="nav-item" v-if="caseid&&caseid!=''" :href="caseUrl+caseid" target="_blank">
              案例征集
            </a>
            <!-- <a class="nav-item" v-else>案例征集</a> -->
            <router-link class="nav-item" :to="`/${global.year}/speech`">演讲专题</router-link>
          </el-row>
          <router-link class="buy-ticket" v-if="global.year==theyear" :to="`/${global.year}/order`">立即报名</router-link>
          <!-- <a class="buy-ticket" v-if="global.year==theyear" @click="toOrder">立即报名</a> -->
          <a class="buy-ticket" v-else>已售罄</a>
          <!-- <router-link class="buy-ticket" to="">已售罄</router-link> -->
        </div>
      </div>
      <div class="navigation mob" ref="navMob" style="height: 54px">
        <div class="f-con">
          <div class="dropdown-wrapper">
            <el-dropdown trigger="click">
              <span class="el-dropdown-link">
                <img
                  class="user-icon"
                  style="max-width: 80%"
                  src="https://f.msup.com.cn/login-user-icon.png"
                />
                <!--  
                  src="../assets/img/login-user-icon.png"-->
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-if="!logined">
                  <div class="nav-item" @click="login()">登录</div>
                </el-dropdown-item>
                <slot v-else>
                  <el-dropdown-item v-for="(nav, i) in mobCenterList" :key="i">
                    <div class="nav-item" @click="goUserCenter(nav.id)">
                      {{ nav.title }}
                    </div>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <a class="nav-item" @click="quit">退出</a>
                  </el-dropdown-item>
                </slot>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <router-link class="logo-wrapper" :to="`/${global.year}/home`" ref="navLogo">
            <img src="../assets/img/mob-logo.png" alt="" />
            <!-- <img src="../assets/img/mob-logo-white.png" alt="" style="margin-top: 10px;" /> -->
          </router-link>
          <div class="dropdown-wrapper" ref="navDrop">
            <el-dropdown trigger="click">
              <span class="el-dropdown-link">
                <!-- <i class="el-icon-arrow-down el-icon-menu" style="width:50px;height:50px;"></i> -->
                <img class="menu" src="../assets/img/header-menu.png" alt="" />
                <!-- <img class="menu" src="../assets/img/header-menu-white.png" alt="" /> -->
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <router-link class="nav-item" :to="`/${global.year}/home`">首页</router-link>
                </el-dropdown-item>
                <el-dropdown-item v-if="hasLive">
                  <router-link class="nav-item" :to="`/${global.year}/live_schedule`">大会直播</router-link>
                </el-dropdown-item>
                <el-dropdown-item>
                  <router-link class="nav-item" :to="`/${global.year}/case`">精选案例</router-link>
                </el-dropdown-item>
                <el-dropdown-item>
                  <router-link class="nav-item" :to="`/${global.year}/schedule`">日程安排</router-link>
                </el-dropdown-item>
                <el-dropdown-item>
                  <router-link class="nav-item" :to="`/${global.year}/speaker`">演讲嘉宾</router-link>
                </el-dropdown-item>
                <el-dropdown-item>
                  <div @click="togglePrevious">
                    往期回顾
                    <i class="el-icon-caret-bottom"></i>
                  </div>
                  <div v-show="show3" class="url-list">
                    <div v-if="global.year!='2022sh'">
                      <a href="/2022sh" target="_blank">
                        2022上海
                      </a>
                    </div>
                    <div v-if="global.year!='2022sz'">
                      <a href="/2022sz" target="_blank">
                        2022深圳
                      </a>
                    </div>
                    <div>
                      <a href="/2021/home" target="_blank">
                        2021深圳
                      </a>
                    </div>
                    <div>
                      <a href="http://giac-history.msup.com.cn/Giac2020/default/index" target="_blank">
                        2020深圳
                      </a>
                    </div>
                    <div>
                      <a href="http://giac-history.msup.com.cn/2019" target="_blank">
                        2019深圳
                      </a>
                    </div>
                    <div>
                      <a href="http://giac-history.msup.com.cn/sh2018/index.html" target="_blank">
                        2018上海
                      </a>
                    </div>
                    <div>
                      <a href="http://giac-history.msup.com.cn/sz2018/index.html" target="_blank">
                        2018深圳
                      </a>
                    </div>
                    <div>
                      <a href="http://giac-history.msup.com.cn/sh2017/index.html" target="_blank">
                        2017上海
                      </a>
                    </div>
                    <div>
                      <a href="http://giac-history.msup.com.cn/bj2016/index.html" target="_blank">
                        2016北京
                      </a>
                    </div>
                  </div>
                </el-dropdown-item>
                <el-dropdown-item v-if="['2023sz','2024sz'].includes(global.year)">
                  <router-link class="nav-item" :to="`/${global.year}/home?loc=sponsor`">
                    赞助伙伴
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item v-if="caseid&&caseid!=''">
                  <a class="nav-item" :href="caseUrl+caseid" target="_blank">
                    案例征集
                  </a>
                </el-dropdown-item>
                <!-- <el-dropdown-item v-else>
                  <a class="nav-item">案例征集</a>
                </el-dropdown-item> -->
                <el-dropdown-item>
                  <router-link class="nav-item" :to="`/${global.year}/speech`">演讲专题</router-link>
                </el-dropdown-item>
                <el-dropdown-item style="border: none">
                  <div class="buy">
                    <router-link class="buy-ticket" v-if="global.year==theyear" :to="`/${global.year}/order`">立即报名</router-link>
                    <!-- <a class="buy-ticket" v-if="global.year==theyear" @click="toOrder">立即报名</a> -->
                    <a class="buy-ticket" v-else>已售罄</a>
                  </div>
                </el-dropdown-item>
                <div class="buy" style="display: none">
                  <router-link class="buy-ticket" v-if="global.year==theyear" :to="`/${global.year}/order`">立即报名</router-link>
                  <!-- <a class="buy-ticket" v-if="global.year==theyear" @click="toOrder">立即报名</a> -->
                  <a class="buy-ticket" v-else>已售罄</a>
                  <!-- <router-link class="buy-ticket" to="">已售罄</router-link> -->
                </div>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
    </slot>
  </div>
</template>

<script>
import { id_arr, getUserInfo, haslive, caseid, caseUrl } from "@/utils/api.js";
export default {
  name: "Header",
  data() {
    return {
      theyear: Object.keys(id_arr).sort((a,b)=>{return b.localeCompare(a)})[0],
      headerInit: false,
      show3: false,
      logined: false,
      token: null,
      userName: "",
      mobCenterList: [
        // 登录后移动端的个人中心列表
        {
          title: "我的门票",
          id: 0,
        },
        {
          title: "我的订单",
          id: 1,
        },
        {
          title: "个人设置",
          id: 2,
        },
      ],
      caseUrl: "",
      caseid: "",
      hasLive: false,
    };
  },
  mounted() {
    this.setInit();
  },
  watch: {
    $route: {
      handler(to, from) {
        console.log('Header-r- ', to, from);
        // console.log(to.path.split('/')[1]);
        if(from && from.fullPath) {
          if(from.fullPath.includes('/userCenter') && from.fullPath.includes('selected=2')) {
            if(localStorage.getItem('link_before_complete')) {
              localStorage.removeItem('link_before_complete');
            }
          }
        }
        if(to && to.path) {
          if(!to.path.includes('/login') && localStorage.getItem('linkurl')) {
            localStorage.removeItem('linkurl');
          }
          if(
            to.path.split('/')[1] != 
            (from && from.path && from.path.split('/')[1])
          ) {
            this.headerInit = false;
            this.global.year = to.path.split('/')[1];
            this.setInit();
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    setInit() {
      this.hasLive = haslive(this.global.year);
      this.caseUrl = caseUrl;
      this.caseid = caseid(this.global.year);
      this.headerInit = true;
      this.checkToken();
      let _this = this;
      setTimeout(() => {
        this.addNavHeight();
        window.addEventListener("resize", () => {
          _this.addNavHeight();
        });
      },200)
    },
    // 
    toOrder() {
      const token = localStorage.getItem("token");
      if (token && token !== null) {
        let url = `${getUserInfo}?token=${this.token}`;
        this.$http
          .jsonp(url)
          .then((data) => {
            return data.json();
          })
          .then((res) => {
            // console.log("header- checkUserInfo",res);
            if (res.status === "200") {
              let flag = true;
              for (const key in res.data) {
                if (!res.data[key] || res.data[key]==="") {
                  flag = false;
                  if (window.confirm("购票需要您先完善个人信息")) {
                    let link = window.location.pathname + window.location.search;
                    localStorage.setItem('link_before_complete', link);
                    this.$router.push({ path: `/${this.global.year}/userCenter`, query: { selected: "2" } });
                    // query: { active: "5" },
                  }
                  break;
                }
              }
              if (flag) {
                this.global.hashParam.qd
                ? this.$router.push({
                    path: `/${this.global.year}/order`,
                    query: {qd: this.global.hashParam.qd}
                  })
                : this.$router.push({
                    path: `/${this.global.year}/order`,
                  });
              }
            } else if (res.code === "1001" || res.status === "1011") {
              this.$message({
                message: "当前登录状态已过期，请重新登录",
                type: "warning",
              });
              window.localStorage.removeItem("logins");
              window.localStorage.removeItem("token");
              let order = `/${this.global.year}/order`+`${this.global.hashParam.qd?'?qd='+this.global.hashParam.qd:''}`;
              window.localStorage.setItem("linkurl", order);
              setTimeout(() => {
                this.$router.push({
                  path: `/${this.global.year}/login`,
                });
              }, 2000);
            } else {
              console.log("获取用户信息失败");
            }
          });
      } else {
        this.$message({
          message: "当前登录状态已过期，请重新登录",
          type: "warning",
        });
        window.localStorage.removeItem("logins");
        window.localStorage.removeItem("token");
        let order = `/${this.global.year}/order`+`${this.global.hashParam.qd?'?qd='+this.global.hashParam.qd:''}`;
        window.localStorage.setItem("linkurl", order);
        setTimeout(() => {
          this.$router.push({
            path: `/${this.global.year}/login`,
          });
        }, 2000);
      }
    },
    checkToken() {
      const _token = localStorage.getItem("token");
      // console.log(this.$route);
      // 如果链接携带了token则使用链接中的token，不存在则检测localStorage中是否有token
      if (this.$route.query.token) {
        this.token = this.$route.query.token;
      } else if (_token && _token !== null) {
        this.token = _token;
      } else {
        return false;
      }
      this.getUserName();
    },
    addNavHeight() {
      // const width = document.body.offsetWidth;
      const width = window.innerWidth;
      // if (+width > 1023) {
      if (+width > 767) {
        this.$refs.navWrapper.style.height = this.$refs.navPc.offsetHeight + "px";
      } else {
        this.$refs.navWrapper.style.height = this.$refs.navMob.offsetHeight + "px";
      }
    },
    togglePrevious() {
      this.show3 = !this.show3;
      event.stopPropagation();
    },
    getUserName() {
      let url = `${getUserInfo}?token=${this.token}`;
      this.$http
        .jsonp(url)
        .then((data) => {
          return data.json();
        })
        .then((data) => {
          // console.log("header- getUserInfo ", data);
          if (data.status === "200") {
            this.userName = data.data.name;
            this.logined = true;
          } else if (data.code === "1001" || data.status === "1011") {
            this.$message({ message: "当前登录状态已过期，请重新登录", type: "warning" });
            window.localStorage.removeItem("token");
            this.logined = false;
          } else {
            console.log("获取用户信息失败");
          }
        });
    },
    login() {
      // window.localStorage.setItem("linkurl", window.location.href);
      window.localStorage.setItem("linkurl", window.location.pathname+window.location.search);
      this.$router.push({ path: `/${this.global.year}/login` });
    },
    register() {
      // window.localStorage.setItem("linkurl", window.location.href);
      window.localStorage.setItem("linkurl", window.location.pathname+window.location.search);
      this.$router.push({ path: `/${this.global.year}/login` });
    },
    quit() {
      window.localStorage.removeItem("logins");
      window.localStorage.removeItem("token");
      this.$nextTick(() => {
        this.logined = false;
      });
      this.$router.push({ path: `/${this.global.year}/home${window.location.search}` });
    },
    goUserCenter(id) {
      console.log('center-id- ', id);
      let centerPath = `/${this.global.year}/userCenter`;
      if(this.$route.path==centerPath && this.$route.query && this.$route.query.selected==id) {
        return;
      } else {
        if (id || id === 0) {
          this.$router.push({ path: centerPath, query: { selected: id } });
        } else {
          this.$router.push({ path: centerPath });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.el-dropdown-menu__item {
  line-height: 30px;
  padding: 4px 20px;
  font-size: 18px;
  font-weight: bold;
  font-family: Source Han Sans CN;
  a {
    font-size: 18px;
    font-weight: bold;
    font-family: Source Han Sans CN;
    color: #003177;
    text-decoration: none;
  }
}
// @media (max-width: 1023px) {
@media (max-width: 767px) {
  .el-dropdown-menu__item {
    .buy {
      width: 100%;
      .buy-ticket {
        width: 73%;
        font-weight: 400;
        color: #7ddbda;
      }
    }
  }
  .el-dropdown {
    .menu {
      max-width: 90%;
    }
    .el-dropdown-link {
      .el-icon-arrow-down.el-icon-menu {
        width: 100%;
        height: 100%;
      }
    }
  }
  .el-dropdown-menu {
    width: 100vw;
    left: 0 !important;
    .el-dropdown-menu__item {
      width: 90%;
      margin: 0 auto;
      border-bottom: 1px solid #e6e6e6;
      color: inherit;
      .url-list {
        div {
          padding-left: 4vw;
          border-bottom: 1px solid #e6e6e6;
        }
      }
      a {
        color: inherit;
        display: block;
      }
    }
    .el-dropdown-menu__item:focus,
    .el-dropdown-menu__item:not(.is-disabled):hover {
      background-color: inherit;
      color: inherit;
    }
    .el-popper .popper__arrow {
      display: none;
    }
  }
}
.nav-wrapper {
  position: fixed;
  z-index: 100;
  .pc {
    display: flex;
  }
  .mob {
    display: none;
  }
  // @media (max-width: 1023px) {
  @media (max-width: 767px) {
    .pc {
      display: none;
    }
    .mob {
      display: block;
    }
  }
}
.pc-wrapper {
  box-shadow: 0px 2px 4px 0px rgba(6, 51, 77, 0.08);
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 120;
  // z-index: 100;
  // display: flex;
  flex-direction: column;
  .login-wrapper {
    width: 100%;
    height: 30px;
    background: #f3f3f3;
    .login {
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      // color: #ffffff;
      color: #666666;
      .login-right {
        margin-right: 10px;
        a {
          height: 30px;
          line-height: 30px;
          color: #666666;
          cursor: pointer;
          margin-left: 15px;
          font-size: 12px;
          font-weight: 500;
        }
        a:hover {
          color: #008aff;
        }
      }
      .login-left {
        display: flex;
        p {
          height: 30px;
          display: inline-block;
          line-height: 30px;
          font-size: 12px;
          font-weight: 500;
        }
        span {
          height: 30px;
          font-size: 12px;
          font-weight: 500;
          margin-left: 15px;
          color: #008aff;
          line-height: 30px;
          cursor: pointer;
        }
        span.vip {
          margin-left: 6px;
        }
        .register {
          margin-left: 0;
        }
      }
      .login-button {
        div {
          color: #008aff;
          display: inline;
        }
      }
    }
  }
  .nav {
    width: 100%;
    flex: 1;
    max-width: 1200px;
    margin: 0 auto 4px;
    background: #ffffff;
    display: flex;
    justify-content: center;
    .logo-wrapper {
      display: flex;
      align-items: center;
      img {
        max-width: 100%;
      }
    }
    .nav-bar {
      display: flex;
      justify-content: space-between;
      flex: 1;
      .nav-item {
        padding: 50px 0 41px;
      }
      .nav-item,
      .nav-item span,
      .nav-item i {
        text-decoration: none;
        font-size: 18px;
        font-weight: 500;
        font-family: Source Han Sans CN;
        color: #003177;
      }
      .to-previous {
        text-decoration: none;
        padding: 59px 20px 41px;
        font-size: 18px;
        font-weight: bold;
        font-family: SourceHanSansCN, SourceHanSansCN-Medium;
        color: #003177;
      }
    }
    .buy-ticket {
      text-decoration: none;
      margin: 40px 0 20px;
      font-size: 20px;
      font-family: Source Han Sans CN, Source Han Sans CN-Regular;
      text-align: center;
      letter-spacing: 0px;
      width: 130px;
      height: 42px;
      line-height: 42px;
      color: #ffffff;
      outline: none;
      border: none;
      cursor: pointer;
      border-radius: 6px;
      background: var(--main-bgcolor); // #003177;
      // background: #2630B2;
      // background: linear-gradient(182deg, #5DA5FF 0%, #0A33B7 99%);
    }
    .buy-ticket:hover {
      opacity: 0.8;
    }
  }
}
// @media (max-width: 1023px) {
@media (max-width: 767px) {
  .navigation.mob > div.f-con {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .navigation {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    padding-left: 6vw;
    padding-right: 6vw;
    white-space: nowrap;
    justify-content: space-between;
    border: 1px solid #e7e7e7;
    background: #fff;
    // background: #2630B2;
    // border-color: transparent;
    .logo-wrapper {
      //
      img {
        // width: 30vw;
        width: 120px;
      }
    }
  }
  .buy {
    width: 100vw;
    .buy-ticket {
      text-decoration: none;
      margin: 20px auto 20px;
      display: block;
      background: #003177;
      text-align: center;
      color: #7ddbda;
      width: 60%;
      height: 6vh;
      line-height: 6vh;
      border-radius: 8vw;
      font-size: 16px;
    }
  }
  .dropdown-wrapper {
    display: flex;
    align-items: center;
  }
}
@media (max-width: 960px) {
  .pc-wrapper .nav {
    .logo-wrapper {
      width: 20%;
    }
    .nav-bar .nav-item {
      min-width: fit-content;
    }
    .buy-ticket {
      width: 100px;
    }
  }
}
</style>