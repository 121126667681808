// EightRoutes
const routes_2022_sz = [
  { path: '/2022sz/home', name: 'TwoSZHome', component: () => import( '../views2022_sz/Home.vue') },
  { path: '/2022sz/home_', name: 'TwoSZHome_', component: () => import( '../views2022_sz/Home_.vue') },
  { path: '/2022sz/case', name: 'TwoSZCase', component: () => import( '../views2022_sz/Case.vue') },
  { path: '/2022sz/speaker', name: 'TwoSZSpeaker', component: () => import( '../views2022_sz/Speaker.vue' /* webpackChunkName: "speaker" */ ) },
  { path: '/2022sz/speaker_', name: 'TwoSZSpeaker_', component: () => import( '../views2022_sz/Speaker_.vue' /* webpackChunkName: "speaker" */ ) },
  { path: '/2022sz/live', name: 'TwoSZLive', component: () => import( '../views2022_sz/Live.vue' /* webpackChunkName: "schedule" */ ) },
  { path: '/2022sz/live_schedule', name: 'TwoSZLiveSchedule', component: () => import( '../views2022_sz/LiveSchedule.vue' ) },
  { path: '/2022sz/schedule', name: 'TwoSZSchedule', component: () => import( '../views2022_sz/Schedule.vue') },
  { path: '/2022sz/oldSchedule', name: 'TwoSZOldSchedule', component: () => import( '../views2022_sz/OldSchedule.vue' ) },
  { path: '/2022sz/speech', name: 'TwoSZSpeech', component: () => import( '../views2022_sz/Speech.vue' /* webpackChunkName: "speech" */ ) },
  { path: '/2022sz/previous', name: 'TwoSZPrevious', component: () => import( '../views2022_sz/Previous.vue' /* webpackChunkName: "previous" */ ) },
  { path: '/2022sz/teacher', name: 'TwoSZTeacher', component: () => import( '../views2022_sz/Teacher.vue' /* webpackChunkName: "teacher" */ ) },
  { path: '/2022sz/subject', name: 'TwoSZSubject', component: () => import( '../views2022_sz/Subject.vue' /* webpackChunkName: "subject" */ ) },
  { path: '/2022sz/course', name: 'TwoSZCourse', component: () => import( '../views2022_sz/Course.vue' /* webpackChunkName: "course" */ ) },
  { path: '/2022sz/ticket', name: 'TwoSZTicket', component: () => import( '../views2022_sz/Ticket.vue' ), },
  { path: '/2022sz/order', name: 'TwoSZOrder', component: () => import( '../views2022_sz/Order.vue' ), },
  { path: '/2022sz/login', name: 'TwoSZLogin', component: () => import( '../views2022_sz/Login.vue' ), },
  { path: '/2022sz/userCenter', name: 'TwoSZUserCenter', component: () => import( '../views2022_sz/UserCenter.vue' ), },
]

export default routes_2022_sz;