// import ToastComponent from './src/Homecomponents/tip.vue'
import ToastComponent from './components/tip.vue'

const Toast = {};

// 注册Toast
Toast.install = function (Vue) {
    // 生成一个Vue的子类
    // 同时这个子类也就是组件
    const ToastConstructor = Vue.extend(ToastComponent)
    // 生成一个该子类的实例
    const instance = new ToastConstructor();

    // 将这个实例挂载在创建的div上
    // 并将此div加入全局挂载点内部
    instance.$mount(document.createElement('div'))
    document.body.appendChild(instance.$el)

    // 通过Vue的原型注册一个方法
    // 让所有实例共享这个方法 
    // (...params)=>{} 将所有参数集成为数组params进行判断，无参时params为空数组[]
    Vue.prototype.$toast = (...params) => {
        let param=params[0]||null, msg='', duration = 1500, type='';

        if(Array.isArray(params) && params[1]) {
          // $toast(x,x,x)
          msg = params[0] || '';
          duration = params[1] || duration;
          type = params[2] || '';
        } else if(param) {
          if(typeof param === 'object') {
            if(Array.isArray(param)) {
              // $toast([x,x,x])
              msg = param[0] || '';
              duration = param[1] || duration;
              type = param[2] || '';
            } else {
              // $toast({x,x,x})
              msg = param.msg || '';
              duration = param.duration || duration;
              type = param.type || '';
            }
          } else if(typeof param === 'string') {
            // $toast(x)
            msg = param;
          }
        }

        instance.message = msg;
        instance.visible = true;
        instance.hint = type;

        setTimeout(() => {
            instance.visible = false;
        }, duration);
    }
}

export default Toast