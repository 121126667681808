<template>
  <!-- 沙龙直播弹窗 -->
  <div v-if="global.popupTime" class="popup_con">
    <div class="popup" id="popup" @click="toclose()" :style="global.popupClose?'top:-100%;':''">
      <div class="pc_p" v-if="ispc">
        <div class="popup_table">
          <div class="popup_cell">
            <div class="box" @click="tostop($event)" :style="`background-image: url(${bg_img});`">
              <h3 class="close" @click="toclose()">
                <!-- <i class="glyphicon glyphicon-remove"></i> -->
                <i>×</i>
              </h3>
              <img src="../assets/img/popup_title.png" alt="" :style="season==1?'':'opacity:0;'"/>
              <h1 :style="season==1?'':'opacity:0;'">{{title}}</h1>
              <h2 :style="season==1?'':'opacity:0;'">-- {{desc}}</h2>
              <p :style="season==1?'':'opacity:0;'"><img src="../assets/img/shijian.png" alt="" /> {{start_time.replaceAll('/','.')}}-{{end_hour}}</p>
              <div 
                @click="toform()" 
                :style="season>1?
                  `opacity: 0;width: 230px;height: 52px;line-height: 52px;
                  ${cli_margintop||'margin-top: 50px;'}
                  margin-left: 4px;border-radius: 50px;`:''"
              >
                报名预约直播 > >
              </div>
              <div class="lecturer" :style="season==1?'':'opacity:0;'">
                <h4>{{lecturer.name}}</h4>
                <p>{{lecturer.company}}</p>
                <p>{{lecturer.position}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mob_p" v-else>
        <div class="popup_table">
          <div class="popup_cell">
            <div class="box" @click="tostop($event)" v-if="season>1">
              <h5 class="close" @click="toclose()">
                <!-- <i class="glyphicon glyphicon-remove"></i> -->
                <i>×</i>
              </h5>
              <div class="all">
                <img :src="bg_img_mob" @click.prevent="toform()" alt=""/>
              </div>
            </div>
            <div class="box" :class="season>1?'':'list'" v-else @click="tostop($event)" :style="`background-image: url(${bg_img_mob});`">
              <h5 class="close" @click="toclose()">
                <!-- <i class="glyphicon glyphicon-remove"></i> -->
                <i>×</i>
              </h5>
              <!-- <img src="../assets/img/popup_title_mob.png" alt=""/>
              <h2>全球互联网架构大会</h2> -->
              <h3>{{desc}}</h3>
              <h1>{{title}}</h1>
              <h2>分享人：{{lecturer.name}}</h2>
              <h4>{{lecturer.company}} {{lecturer.position}}</h4>
              <p>{{start_time.replaceAll('/','.')}}</p>
              <div @click="toform()">报名预约直播 > > ></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--  -->
    <div class="popup_open" :class="global.popupClose?'is_show':'not_show'">
      <div>
        <!-- <img src="../assets/img/popup_title_mob.png" alt=""/> -->
        <h1>{{desc}}</h1>
        <h2 :style="season>1?'padding: 0 5px;':''">{{title}}</h2>
        <p @click="toopen()">点击查看</p>
      </div>
    </div>
  </div>
</template>

<script>
import {caseUrl} from "@/utils/api.js";

export default {
  name: "Popup",
  data() {
    return {
      ispc: true,
      // 第三期(2022上海站)
      season: 6,
      title: "开源数据库的探索之道",
      desc: "GIAC线上沙龙·第三期",
      start_time: "2022/09/29 20:00",
      end_hour: "21:00",
      end_time: "2022/09/29 21:00",
      form_url: `${caseUrl}3097`,
      lecturer: {name:"", company:"", position:""},
      bg_img: require("../assets/img/2022/popup_bg_s6.png"),
      bg_img_mob: require("../assets/img/2022/popup_bg_s6_mob.png"),
      cli_margintop: "margin-top: 79px;",
      // 往期
      oldseason: {
        // 第二期(2022上海站)
        // season: 5,
        // title: "夜话数字化转型之路锵锵三人行",
        // desc: "GIAC线上沙龙·第二期",
        // start_time: "2022/08/30 20:00",
        // end_hour: "21:00",
        // end_time: "2022/08/30 21:00",
        // form_url: `${caseUrl}3066`,
        // lecturer: {name:"", company:"", position:""},
        // bg_img: require("../assets/img/2022/popup_bg_s5.jpg"),
        // bg_img_mob: require("../assets/img/2022/popup_bg_s5_mob.png"),
        // cli_margintop: "margin-top: 78px;",
        // 第一期(2022上海站)
        // season: 4,
        // title: "Web3前沿动态",
        // desc: "GIAC线上沙龙·第一期",
        // start_time: "2022/08/23 19:00",
        // end_hour: "20:20",
        // end_time: "2022/08/23 20:20",
        // form_url: `${caseUrl}3029`,
        // lecturer: {name:"", company:"", position:""},
        // bg_img: require("../assets/img/2022/popup_bg_s4.png"),
        // bg_img_mob: require("../assets/img/2022/popup_bg_s4_mob.png"),
        // 第三期
        // season: 3,
        // title: "Web3应用的机遇与挑战",
        // desc: "GIAC线上沙龙·第三期",
        // start_time: "2022/07/19 19:00",
        // end_hour: "20:15",
        // end_time: "2022/07/19 20:15",
        // form_url: `${caseUrl}2934`,
        // lecturer: {name:"", company:"", position:""},
        // bg_img: require("../assets/img/2022/popup_bg_s3_1.png"),
        // bg_img_mob: require("../assets/img/2022/popup_bg_s3_1_mob.png"),
        // // 第二期
        // season: 2,
        // title: "2022年大前端热点解读",
        // desc: "GIAC线上沙龙·第二期",
        // start_time: "2022/07/07 19:30",
        // end_hour: "20:30",
        // end_time: "2022/07/07 20:30",
        // form_url: `${caseUrl}2916`,
        // lecturer: {name:"狼叔", company:"Node.js", position:"技术布道者"},
        // bg_img: require("../assets/img/2022/popup_bg_langshu.png"),
        // bg_img_mob: require("../assets/img/2022/popup_bg_langshu_mob.png"),
        // 第一期
        // season: 1,
        // title: "云原生时代技术演进之路",
        // desc: "GIAC线上沙龙·第一期",
        // start_time: "2022/05/31 19:30",
        // end_hour: "20:30",
        // end_time: "2022/05/31 20:30",
        // form_url: "https://vip.msup.com.cn/index.php/weixin/feedback/form-feedback?formId=2825", // http://p.msup.cn/_oNH 直播沙龙报名
        // form_url: "https://vip.msup.com.cn/index.php/weixin/feedback/form-feedback?formId=2837",
        // lecturer: {name:"毛剑", company:"bilibili", position:"技术委员会主席"},
        // bg_img: `${require("../assets/img/2022/popup_bg_maojian.png")}`,
        // bg_img_mob: `${require("../assets/img/2022/popup_bg_mob.png")}`,
        // bg_img: "",
        // bg_img_mob: "",
      }
    }
  },
  created() {
    this.inittime();
  },
  methods: {
    inittime: function() {
      let time = new Date();
      // if(time >= new Date(this.end_time)){
      //   this.global.popupClose = true;
      // }
      // console.log(time);
      // console.log(new Date(this.start_time));
      // console.log(new Date(this.end_time));
      this.global.popupClose = (time>=new Date(this.end_time)) ? true : false;
      this.global.popupTime = (time<new Date(this.end_time)) ? true : false;
      if (this.detectDeviceType() === "Desktop") {
        this.ispc = true;
      } else if (this.detectDeviceType() === "Mobile") {
        this.ispc = false;
      }
      window.addEventListener("resize", () => {
        if (this.detectDeviceType() === "Desktop") {
          this.ispc = true;
        } else if (this.detectDeviceType() === "Mobile") {
          this.ispc = false;
        }
      })
      // alert(`GIAC-${this.ispc} ${$('.popup').css('z-index')}`);
      // alert(document.body.clientWidth);
    },
    detectDeviceType() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
        ? "Mobile"
        : "Desktop";
    },
    // 
    toopen: function() {
      // 
      this.global.popupClose = false;
      this.$forceUpdate();
    },
    toclose: function() {
      // document.getElementById("popup").style.top = "-100%";
      this.global.popupClose = true;
      this.$forceUpdate();
    },
    tostop: function(e) {
      e.stopPropagation();
    },
    toform: function() {
      // console.log(this.form_url);
      // console.log(this.global.hashParam);
      let url = 
        this.global.hashParam&&this.global.hashParam["qd"] 
        ? `${this.form_url}&qd=${this.global.hashParam.qd}` 
        : this.form_url;
      window.open(url, "_blank");
    },
  },
}
</script>

<style lang="scss">
// .popup_con {
//   width: 0;
//   height: 0;
//   // top: 0;
//   // bottom: 0;
//   position: fixed;
//   z-index: 500;
//   transform: translateZ(500px);
// }
.popup {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 500;
  // z-index: 110;
  // transform: translateZ(5000px);
  position: fixed;
  background-color: rgba(0,0,0,0.5);
  transition: top 0.5s ease;
  * {
    margin: 0;
  }
  .pc_p, .mob_p {
    width: 100%;
    height: 100%;
  }
  .popup_table {
    width: 100%;
    height: 100%;
    display: table;
    .popup_cell {
      width: 100%;
      height: 100%;
      display: table-cell;
      vertical-align: middle;
      .box {
        width: 100%;
        margin: 0 auto;
        position: relative;
        background-repeat: no-repeat;
        .close {
          cursor: pointer;
          text-align: center;
          position: absolute;
          border-radius: 50px;
          border: 1px solid rgba(255, 255, 255, 0.8);
          i {
            font-weight: 400;
            font-style: normal;
            color: rgba(255, 255, 255, 0.75);
            display: inline-block;
          }
        }
      }
    }
  }
  .pc_p .popup_table {
    .popup_cell {
      .box {
        max-width: 750px;
        height: 425px;
        padding: 35px 40px 55px 40px;
        text-align: left;
        // background-image: url("../assets/img/popup_bg.png");
        // background-image: url("../assets/img/2022/popup_bg_maojian.png");
        background-position: center;
        position: relative;
        h3 {
          width: 32px;
          height: 32px;
          top: 5px;
          right: 3px;
          // top: 8px;
          // right: 8px;
          // top: 12px;
          // right: 12px;
          // padding-left: 2px;
          // i.glyphicon {
          i {
            height: 28px;
            line-height: 28px;
            font-size: 38px;
          }
        }
        h1 {
          height: 34px;
          line-height: 34px;
          font-size: 34px;
          // height: 50px;
          // line-height: 50px;
          // font-size: 50px;
          font-family: Source Han Sans CN Heavy;
          font-weight: 800;
          color: #FFFFFF;
          margin-top: 60px;
        }
        h2 {
          height: 24px;
          line-height: 24px;
          font-size: 24px;
          font-family: Adobe Heiti Std;
          // font-family: Source Han Sans CN Regular;
          // font-weight: 400;
          font-weight: 500;
          color: #FFFFFF;
          margin-top: 15px;
          // margin-top: 20px;
        }
        >p {
          height: 18px;
          line-height: 18px;
          font-size: 16px;
          font-family: Adobe Heiti Std;
          // font-family: Source Han Sans CN Regular;
          // font-weight: 400;
          font-weight: 500;
          color: #FFFFFF;
          margin-top: 20px;
          display: flex;
          flex-wrap: nowrap;
          img {
            height: 18px;
            margin-right: 12px;
          }
        }
        div:not(.lecturer) {
          width: 206px;
          height: 46px;
          line-height: 46px;
          margin-top: 55px;
          font-size: 20px;
          font-family: Source Han Sans CN Medium;
          // font-size: 18px;
          // font-family: Source Han Sans CN Regular;
          // font-weight: 400;
          font-weight: 500;
          color: #3131C6;
          text-align: center;
          background: #FFFFFF;
          border-radius: 23px;
          cursor: pointer;
        }
        div.lecturer {
          top: 130px;
          right: 30px;
          position: absolute;
          h4 {
            font-size: 24px;
            font-family: PingFang SC Heavy;
            font-weight: 800;
            color: #FFFFFF;
          }
          p {
            font-size: 16px;
            font-family: Alibaba PuHuiTi Regular;
            font-weight: 800;
            color: #FFFFFF;
          }
        }
      }
    }
  }
  .mob_p {
    .popup_table {
      .popup_cell {
        .box.list {
          // max-width: 615px;
          // height: 685px;
          max-width: 305px;
          height: 345px;
          padding: 30px 15px;
          text-align: center;
          // background-image: url("../assets/img/popup_bg_mob.png");
          background-position: top center;
          background-size: contain;
          h5 {
            top: 10px;
            right: 10px;
          }
        }
        .box:not(.list) {
          h5 {
            top: 10px;
            right: 10vw;
          }
        }
        .box {
          >div.all {
            width: 100vw;
            max-width: 500px;
            margin: 0 auto;
            img {
              width: 100%;
              height: inherit;
            }
          }
          h5 {
            width: 25px;
            height: 25px;
            padding-left: 1px;
            // i.glyphicon {
            i {
              height: 21px;
              line-height: 21px;
              font-size: 34px;
            }
          }
          img:not(.all) {
            height: 20px;
          }
          h1 {
            // height: 40px;
            // line-height: 40px;
            height: 24px;
            line-height: 24px;
            font-size: 24px;
            font-family: Alibaba PuHuiTi Heavy;
            font-weight: 800;
            color: #FFFFFF;
            // color: #3B44B9;
            // background-color: #FFFFFF;
            margin-top: 35px;
          }
          h2 {
            // height: 19px;
            // line-height: 19px;
            // font-size: 19px
            height: 20px;
            line-height: 20px;
            font-size: 20px;
            font-family: Alibaba PuHuiTi Heavy;
            font-weight: 500;
            color: #FFFFFF;
            margin-top: 30px;
          }
          h4 {
            height: 13px;
            line-height: 13px;
            font-size: 13px;
            font-family: Alibaba PuHuiTi Regular;
            font-weight: 500;
            color: #FFFFFF;
            margin-top: 10px;
          }
          h3 {
            width: 80%;
            max-width: 440px;
            margin: 0 auto;
            line-height: 35px;
            font-size: 18px;
            font-family: Alibaba PuHuiTi Regular;
            font-weight: 400;
            color: #FFFFFF;
            border: 1px solid #FFFFFF;
            border-radius: 12px;
          }
          // h2:nth-of-type(1) {
          //   margin-top: 10px;
          //   font-family: Alibaba PuHuiTi Bold;
          //   font-weight: bold;
          // }
          // h2:nth-of-type(2) {
          //   margin-top: 20px;
          //   font-family: Alibaba PuHuiTi Medium;
          //   font-weight: 500;
          // }
          >p {
            height: 18px;
            line-height: 18px;
            font-size: 14px;
            font-family: Alibaba PuHuiTi Regular;
            font-weight: 500;
            color: #E9E9E9;
            margin-top: 25px;
          }
          div:not(.all) {
            width: 95%;
            height: 44px;
            line-height: 44px;
            margin: 0 auto;
            margin-top: 30px;
            font-size: 18px;
            font-family: Alibaba PuHuiTi Medium;
            // font-weight: 400;
            font-weight: 500;
            color: #3B44B9;
            text-align: center;
            background: rgba(255, 255, 255, 0.8);
            box-shadow: 0px 10px 20px 0px rgba(31, 75, 189, 0.5);
            border-radius: 44px;
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media (min-width: 500px) {
  .popup .mob_p .popup_table .popup_cell .box:not(.list) h5 {
    right: 50vw;
    margin-right: -195px;
  }
}

.popup_open {
  width: 180px;
  height: 170px;
  padding: 10px 0;
  z-index: 499;
  // z-index: 99;
  // transform: translateZ(499px);
  // right: -150px;
  bottom: 75px;
  position: fixed;
  border-radius: 6px;
  background: linear-gradient(45deg, #5da5ff 0%, #2b228d 100%);
  // transition: right 1.25s ease;
  // animation: popup_open 1.25s ease;
  // img {
  //   width: 100%;
  // }
  h1 {
    margin-top: 5px;
    font-size: 16px;
    font-family: Source Han Sans CN Medium;
    font-weight: 500;
    text-align: center;
    color: #FFFFFF;
  }
  h2 {
    min-height: 40px;
    margin-top: 15px;
    font-size: 24px;
    font-family: Source Han Sans CN Heavy;
    text-align: center;
    color: #FFFFFF;
  }
  p {
    width: fit-content;
    margin: 0 auto;
    margin-top: 18px;
    padding: 0 15px;
    font-size: 15px;
    font-family: Source Han Sans CN Regular;
    color: #FFFFFF;
    border-radius: 3px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    background-color: rgba(255, 255, 255, 0.1);
    cursor: pointer;
  }
}
.is_show {
  right: 2px;
  animation: popup_close 1.25s ease;
}
.not_show {
  right: -180px;
  animation: popup_open 0.5s ease;
}
@keyframes popup_close {
  0% {right: -180px;}
  40% {right: -180px;}
  100% {right: 2px;}
}
@keyframes popup_open {
  0% {right: 2px;}
  100% {right: -180px;}
}

// .pc_p {
//   display: block;
// }
// .mob_p {
//   display: none;
// }
// // @media (device-width: 767px) { // 设备宽度
// @media (max-width: 767px) {
//   .pc_p {
//     display: none;
//   }
//   .mob_p {
//     display: block;
//   }
// }
</style>