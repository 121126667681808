<template>
  <div class="nav-wrapper" ref="navWrapper">
    <div class="pc-wrapper pc" ref="navPc">
      <div class="nav">
        <router-link class="logo-wrapper" to="/2021">
          <img src="../assets/img/header-logo.png" alt="" />
        </router-link>
        <el-row class="nav-bar">
          <router-link class="nav-item" to="/2021">首页</router-link>
          <router-link class="nav-item" to="/2021/case">精选案例</router-link>
          <router-link class="nav-item" to="/2021/schedule">日程安排</router-link>
          <router-link class="nav-item" to="/2021/speaker">演讲嘉宾</router-link>
          <el-dropdown class="nav-item" trigger="click">
            <span class="el-dropdown-link">
              往期回顾<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <!-- <el-dropdown-item><a href="" target="_blank">2021深圳</a></el-dropdown-item> -->
              <el-dropdown-item>
                <a href="http://giac-history.msup.com.cn/Giac2020/default/index" target="_blank">
                  2020深圳
                </a>
              </el-dropdown-item>
              <el-dropdown-item>
                <a href="http://giac-history.msup.com.cn/2019" target="_blank">
                  2019深圳
                </a>
              </el-dropdown-item>
              <el-dropdown-item>
                <a href="http://giac-history.msup.com.cn/sh2018/index.html" target="_blank">
                  2018上海
                </a>
              </el-dropdown-item>
              <el-dropdown-item>
                <a href="http://giac-history.msup.com.cn/sz2018/index.html" target="_blank">
                  2018深圳
                </a>
              </el-dropdown-item>
              <el-dropdown-item>
                <a href="http://giac-history.msup.com.cn/sh2017/index.html" target="_blank">
                  2017上海
                </a>
              </el-dropdown-item>
              <el-dropdown-item>
                <a href="http://giac-history.msup.com.cn/bj2016/index.html" target="_blank">
                  2016北京
                </a>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <a class="nav-item" href="http://p.msup.cn/VBjR" target="_blank">案例征集</a>
          <router-link class="nav-item" to="/2021/speech">演讲专题</router-link>
        </el-row>
        <router-link class="buy-ticket" to="">已售罄</router-link>
        <!-- <router-link class="buy-ticket" to="/2021/ticket">立即报名</router-link> -->
      </div>
    </div>
    <div class="navigation mob" ref="navMob" style="height: 54px">
      <router-link class="logo-wrapper" to="/home" ref="navLogo">
        <img src="../assets/img/mob-logo.png" alt="" />
      </router-link>
      <div class="dropdown-wrapper" ref="navDrop">
        <el-dropdown trigger="click">
          <span class="el-dropdown-link">
            <!-- <i class="el-icon-arrow-down el-icon-menu" style="width:50px;height:50px;"></i> -->
            <img class="menu" src="../assets/img/header-menu.png" alt="" />
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              <router-link class="nav-item" to="/2021/home">首页</router-link>
            </el-dropdown-item>
            <el-dropdown-item>
              <router-link class="nav-item" to="/2021/case">精选案例</router-link>
            </el-dropdown-item>
            <el-dropdown-item>
              <router-link class="nav-item" to="/2021/schedule">日程安排</router-link>
            </el-dropdown-item>
            <el-dropdown-item>
              <router-link class="nav-item" to="/2021/speaker">演讲嘉宾</router-link>
            </el-dropdown-item>
            <el-dropdown-item>
              <div @click="togglePrevious">
                往期回顾
                <i class="el-icon-caret-bottom"></i>
              </div>
              <div v-show="show3" class="url-list">
                <div>
                  <a href="http://giac-history.msup.com.cn/Giac2020/default/index" target="_blank">
                    2020深圳
                  </a>
                </div>
                <div>
                  <a href="http://giac-history.msup.com.cn/2019" target="_blank">
                    2019深圳
                  </a>
                </div>
                <div>
                  <a href="http://giac-history.msup.com.cn/sh2018/index.html" target="_blank">
                    2018上海
                  </a>
                </div>
                <div>
                  <a href="http://giac-history.msup.com.cn/sz2018/index.html" target="_blank">
                    2018深圳
                  </a>
                </div>
                <div>
                  <a href="http://giac-history.msup.com.cn/sh2017/index.html" target="_blank">
                    2017上海
                  </a>
                </div>
                <div>
                  <a href="http://giac-history.msup.com.cn/bj2016/index.html" target="_blank">
                    2016北京
                  </a>
                </div>
              </div>
            </el-dropdown-item>
            <el-dropdown-item>
              <a class="nav-item" href="http://p.msup.cn/VBjR" target="_blank">案例征集</a>
            </el-dropdown-item>
            <el-dropdown-item>
              <router-link class="nav-item" to="/2021/speech">演讲专题</router-link>
            </el-dropdown-item>
            <div class="buy">
              <!-- <router-link class="buy-ticket" to="/2021/ticket">立即报名</router-link> -->
              <router-link class="buy-ticket" to="">已售罄</router-link>
            </div>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      show3: false,
    };
  },
  mounted() {
    this.addNavHeight();
    let _this = this;
    // window.onresize = ()=>{
    //   _this.addNavHeight();
    // };
    window.addEventListener('resize', ()=>{
      _this.addNavHeight();
    })
  },
  methods: {
    addNavHeight() {
      // const width = document.body.offsetWidth;
      const width = window.innerWidth;
      if (+width < 1023) {
        this.$refs.navWrapper.style.height =
          this.$refs.navMob.offsetHeight + "px";
      } else {
        this.$refs.navWrapper.style.height =
          this.$refs.navPc.offsetHeight + "px";
      }
    },
    togglePrevious() {
      this.show3 = !this.show3;
      event.stopPropagation();
    },
  },
};
</script>

<style lang="scss" scoped>
.el-dropdown-menu__item {
  padding: 4px 20px;
  font-size: 18px;
  font-weight: bold;
  font-family: SourceHanSansCN;
  a {
    text-decoration: none;
    color: #003177;
  }
}
.pc-wrapper {
  box-shadow: 0px 2px 4px 0px rgba(6, 51, 77, 0.08);
  background: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  .nav {
    flex: 1;
    max-width: 1200px;
    margin: 0 auto 4px;
    background: #ffffff;
    display: flex;
    justify-content: center;
    .logo-wrapper {
      display: flex;
      align-items: center;
      img {
        max-width: 100%;
      }
    }
    .nav-bar {
      display: flex;
      justify-content: space-between;
      flex: 1;
      .nav-item {
        text-decoration: none;
        padding: 50px 0 41px;
        font-size: 18px;
        font-weight: 500;
        font-family: Source Han Sans CN;
        color: #003177;
      }
      .to-previous {
        text-decoration: none;
        padding: 59px 20px 41px;
        font-size: 18px;
        font-weight: bold;
        font-family: SourceHanSansCN, SourceHanSansCN-Medium;
        color: #003177;
      }
    }
    .buy-ticket {
      text-decoration: none;
      margin: 40px 0 20px;
      font-size: 20px;
      font-family: Source Han Sans CN, Source Han Sans CN-Regular;
      text-align: center;
      letter-spacing: 0px;
      width: 130px;
      height: 42px;
      line-height: 42px;
      background: #003177;
      color: #ffffff;
      border-radius: 6px;
      outline: none;
      border: none;
      cursor: pointer;
    }
    .buy-ticket:hover {
      opacity: 0.8;
    }
  }
}
@media (max-width: 1023px) {
  .pc {
    display: none;
  }
  .mob {
    display: flex;
  }
  .navigation {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    white-space: nowrap;
    background: #fff;
    justify-content: space-between;
    padding-left: 6vw;
    padding-right: 6vw;
    border: 1px solid #e7e7e7;
    .logo-wrapper {
      img {
        // width: 30vw;
        width: 120px;
      }
    }
  }
  .buy {
    width: 100vw;
    .buy-ticket {
      text-decoration: none;
      margin: 20px auto 20px;
      display: block;
      background: #003177;
      text-align: center;
      color: #7ddbda;
      width: 60%;
      height: 6vh;
      line-height: 6vh;
      border-radius: 8vw;
      font-size: 16px;
    }
  }
  .dropdown-wrapper {
    display: flex;
    align-items: center;
  }
  .el-dropdown {
    .menu {
      max-width: 90%;
    }
    .el-dropdown-link {
      .el-icon-arrow-down.el-icon-menu {
        width: 100%;
        height: 100%;
      }
    }
  }
  .el-dropdown-menu {
    width: 100vw;
    left: 0 !important;
    .el-dropdown-menu__item {
      width: 90%;
      margin: 0 auto;
      border-bottom: 1px solid #e6e6e6;
      color: inherit;
      .url-list {
        div {
          padding-left: 4vw;
          border-bottom: 1px solid #e6e6e6;
        }
      }
      a {
        color: inherit;
        display: block;
      }
    }
    .el-dropdown-menu__item:focus,
    .el-dropdown-menu__item:not(.is-disabled):hover {
      background-color: inherit;
      color: inherit;
    }

    .el-popper .popper__arrow {
      display: none;
    }
  }
}
@media (min-width: 1023px) {
  .pc {
    display: flex;
  }
  .mob {
    display: none;
  }
}
</style>