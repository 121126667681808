<template>
  <div :class="`bottom ${global.year=='2024sz'?'img':''}`">
    <slot v-if="global.year=='2024sz'">
      <div class="bottompc bgcolor s1-2">
        <a :href="formurl+(qd ? '&qd='+qd : '')" target="_blank">
          <img src="../assets/img/2024/bottom_transparent.png" alt="">
        </a>
        <span class="close" @click="toClose('pc')">×</span>
      </div>
      <div class="bottommob bgcolor s2-2">
        <a :href="formurl+(qd ? '&qd='+qd : '')" target="_blank">
          <img src="../assets/img/2024/bottom_transparent_mob.png" alt="">
        </a>
        <span class="close" @click="toClose('mob')">×</span>
      </div>
    </slot>
    <slot v-else>
      <div class="bottompc">
        <div class="item">
          <span>联系顾问，咨询详情</span>
          <button @click="toNext(1)">峰会咨询</button>
        </div>
        <div class="item">
          <!-- <span>使用推荐码报名，享5.5折优惠</span> -->
          <!-- <button @click="toNext(2)">推荐码报名</button> -->
          <span>即将开幕，抢购门票</span>
          <button v-if="global.year==theyear" @click="toNext(2)">立即报名</button>
          <button v-else>已售罄</button>
        </div>
        <!-- <div class="item">
          <span>限时优惠，点击抢票</span>
          <button @click="toNext(2)">领取折扣</button>
        </div> -->
      </div>
      <div class="bottommob">
        <div class="item" @click="toNext(1)">内容咨询</div>
        <!-- <div class="item" @click="toNext(2)">推荐码报名</div> -->
        <div class="item" v-if="global.year==theyear" @click="toNext(2)">立即报名</div>
        <div class="item" v-else>已售罄</div>
      </div>
    </slot>
  </div>
</template>

<script>
import { id_arr, getUserInfo, _formurl, formid, formUrl } from "@/utils/api.js";

export default {
  name: "Bottom",
  data() {
    let _this = this;
    return {
      theyear: Object.keys(id_arr).sort((a,b)=>{return b.localeCompare(a)})[0],
      formurl: formUrl+formid(_this.global.year),
      qd: _this.global.hashParam.qd,
    }
  },
  methods: {
    toClose(type) {
      // console.log(type);
      if(type == 'pc') {
        $('.bottompc').addClass('isclose');
        $('.giac-footer.pc').addClass('isclose');
      } else if(type == 'mob') {
        $('.bottommob').addClass('isclose');
        $('.giac-footer.mob').addClass('isclose');
      }
    },
    toNext(n) {
      if (n == 1) {
        let form_url = _formurl(this.global.year);
        if(form_url && form_url!=""){
          this.global.hashParam&&this.global.hashParam.qd&&this.global.hashParam.qd!="" 
          ? window.open(form_url+'&qd='+this.global.hashParam.qd, '_blank') 
          : window.open(form_url, '_blank');
          return;
        } else {
          // console.log(this.global.year,this.global.hashParam);
          let fid = formid(this.global.year);
          fid && fid!="" 
          ? ( this.global.hashParam&&this.global.hashParam.qd&&this.global.hashParam.qd!="" 
              ? window.open(formUrl+fid+'&qd='+this.global.hashParam.qd, '_blank') 
              : window.open(formUrl+fid, '_blank') 
            )
          : this.$toast('敬请期待') ;
          return;
        }
      } else {
        this.$router.push({ path: `/2024sz/order` }); // 登录判断转至order页面
        return;
      }
    },

    _toNext(n) {
      if (n == 1) {
        let form_url = _formurl(this.global.year);
        if(form_url && form_url!=""){
          this.global.hashParam&&this.global.hashParam.qd&&this.global.hashParam.qd!="" 
          ? window.open(form_url+'&qd='+this.global.hashParam.qd, '_blank') 
          : window.open(form_url, '_blank');
          return;
        } else {
          // console.log(this.global.year,this.global.hashParam);
          let fid = formid(this.global.year);
          fid && fid!="" 
          ? ( this.global.hashParam&&this.global.hashParam.qd&&this.global.hashParam.qd!="" 
              ? window.open(formUrl+fid+'&qd='+this.global.hashParam.qd, '_blank') 
              : window.open(formUrl+fid, '_blank') 
            )
          : this.$toast('敬请期待') ;
          return;
        }
      }
      const token = localStorage.getItem("token");
      // console.log(token);
      if (token && token !== null) {
        let url = `${getUserInfo}?token=${token}`;
        this.$http
          .jsonp(url)
          .then((data) => {
            return data.json();
          })
          .then((res) => {
            console.log("home- checkUserInfo",res);
            if (res.status === "200") {
              let flag = true;
              for (const key in res.data) {
                if (res.data[key] === "") {
                  flag = false;
                  if (window.confirm("购票需要您先完善个人信息")) {
                    let link = window.location.pathname + window.location.search;
                    localStorage.setItem('link_before_complete', link);
                    this.$router.push({
                      path: `/${this.global.year}/userCenter`,
                      // query: { active: "5" },
                      query: { selected: "2" },
                    });
                  }
                  break;
                }
              }
              if (flag) {
                this.global.hashParam.qd
                ? this.$router.push({
                    path: `/${this.global.year}/order`,
                    query: {qd: this.global.hashParam.qd}
                  })
                : this.$router.push({
                    path: `/${this.global.year}/order`,
                  });
              }
            } else if (res.code === "1001" || res.status === "1011") {
              this.$message({
                message: "当前登录状态已过期，请重新登录",
                type: "warning",
              });
              window.localStorage.removeItem("logins");
              window.localStorage.removeItem("token");
              let order = `/${this.global.year}/order`+`${this.global.hashParam.qd?'?qd='+this.global.hashParam.qd:''}`;
              window.localStorage.setItem("linkurl", order);
              setTimeout(() => {
                this.$router.push({
                  path: `/${this.global.year}/login`,
                });
              }, 2000);
            } else {
              console.log("获取用户信息失败");
            }
          });
      } else {
        this.$message({
          message: "当前登录状态已过期，请重新登录",
          type: "warning",
        });
        window.localStorage.removeItem("logins");
        window.localStorage.removeItem("token");
        let order = `/${this.global.year}/order`+`${this.global.hashParam.qd?'?qd='+this.global.hashParam.qd:''}`;
        window.localStorage.setItem("linkurl", order);
        setTimeout(() => {
          this.$router.push({
            path: `/${this.global.year}/login`,
          });
        }, 2000);
      }
    },
    toNext_(n) {
      // let hash = "";
      // hash = this.global.hashParam["qd"];
      if (n == 1) {
        window.open(formUrl+formid(this.global.year),'_blank')
        // if (hash) {
        //   window.open(
        //     "http://acrm.msup.com.cn/form/detail?form_id=14&qd=" + hash,
        //     "_blank"
        //   );
        // } else {
        //   window.open(
        //     "http://acrm.msup.com.cn/form/detail?form_id=14",
        //     "_blank"
        //   );
        // }
      } else if (n == 2) {
        this.$router.push({ path: `/${this.global.year}/ticket` });
        // window.open('http://pr.msup.cn/L2YfU2','_blank')
        // if(hash){
        //   window.open('http://acrm.msup.com.cn/form/detail?form_id=23&qd='+hash,'_blank')
        // }else{
        //   window.open('http://acrm.msup.com.cn/form/detail?form_id=23','_blank')
        // }
      }
    },
  },
};
</script>

<style lang="scss">
.bottom .bottompc {
  width: 100%;
  height: 68px;
  padding: 14px 0;
  background: rgba(0, 0, 0, 0.3);
  display: inline-flex;
  flex-wrap: nowrap;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
}
.item {
  /* width: 50%; */
  line-height: 40px;
  font-size: 18px;
  font-family: Source Han Sans CN Regular;
  font-weight: 500;
  color: #ffffff;
}
.bottompc .item:nth-of-type(1) {
  text-align: right;
  padding-right: 35px;
}
.bottompc .item:nth-of-type(2) {
  text-align: left;
  padding-left: 35px;
}
.bottom.img {
  .bgcolor {
    &::after {
      content: '';
      width: 100vw;
      display: block;
      position: absolute;
      z-index: 90;
      top: 0;
      bottom: 0;
      // background-color: rgba(255,255,255,0.25);
    }
    &.s1-1 {
      background: linear-gradient(180deg, rgba(255,255,255,0) 0%, #b9e9fb 100%);
    }
    &.s1-2 {
      background: linear-gradient(180deg, rgba(255,255,255,0) 0%, #b9e9fb 100%);
      &::after {
        background-color: rgba(255,255,255,0.25);
      }
    }
    &.s2-1 {
      background: linear-gradient(180deg, rgba(185,233,251,0.5) 0%, rgba(255,255,255,0) 45%, #b9e9fb 100%);
    }
    &.s2-2 {
      background: linear-gradient(180deg, rgba(185,233,251,0.5) 0%, rgba(255,255,255,0) 45%, #b9e9fb 100%);
      &::after {
        background-color: rgba(255,255,255,0.25);
      }
    }
    &.s3-1 {
      background-color: rgba(185, 233, 251, 0.35);
    }
    &.s3-2 {
      background-color: rgba(185, 233, 251, 0.35);
      &::after {
        background-color: rgba(255,255,255,0.25);
      }
    }
    &.isclose {
      // bottom: -100%;
      // top: 100vh;
      bottom: -150px;
      animation: isclose 0.4s;
    }
    @keyframes isclose {
      0% {
        bottom: 0;
      }
      100% {
        bottom: -150px;
      }
    }
    img {
      height: auto;
      position: relative;
      z-index: 100;
    }
    .close {
      position: absolute;
      top: 0;
      z-index: 110;
      display: block;
      cursor: pointer;
      font-weight: 300;
      color: #0c27b7;
      text-shadow: 0px 1px 2px #ffffff;
      user-select: none;
    }
  }
  .bottompc {
    height: auto;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 35px;
    padding-right: 35px;
    // background: linear-gradient(180deg, rgba(255,255,255,0) 0%, #b9e9fb 100%);
    // background: linear-gradient(180deg, rgba(185,233,251,0.5) 0%, rgba(255,255,255,0) 40%, #b9e9fb 100%);
    // background-color: rgba(185, 233, 251, 0.35);
    img {
      width: 100%;
      // max-width: 2000px;
      max-width: 800px;
    }
    .close {
      right: 10px;
      // font-size: 50px;
      font-size: 40px;
      opacity: 0.6;
      &:hover {
        opacity: 1;
      }
    }
  }
  .bottommob {
    justify-content: center;
    img {
      width: 80vw;
      // max-width: 600px;
      max-width: 400px;
    }
    .close {
      right: 5px;
      font-size: 35px;
      opacity: 1;
    }
  }
}
.item button {
  width: 150px;
  margin-left: 20px;
  text-align: center;
  border: none;
  border-radius: 20px;
  background: #003177;
  /* background: #2630B2; */
  /* background: linear-gradient(182deg, #5DA5FF 0%, #0A33B7 99%); */
}

.bottom .bottommob {
  width: 100%;
  display: none;
  flex-wrap: nowrap;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
}
.bottommob .item {
  width: 100%;
  line-height: 50px;
  font-size: 16px;
  /* font-family: PingFang SC Medium;
  font-weight: 400; */
  text-align: center;
}
.bottommob .item:nth-of-type(1) {
  background: #f6f9ff;
  border: 1px solid #003177;
  color: #293f76;
  /* border-color: #2630B2;
  color: #2630B2; */
}
.bottommob .item:nth-of-type(2) {
  background: #293f76;
  border: 1px solid #003177;
  color: #ffffff;
  /* background: #2630B2;
  border-color: #2630B2; */
}
// @media (max-width: 800px) {
@media (max-width: 767px) {
  .bottompc {
    display: none !important;
  }
  .bottommob {
    display: inline-flex !important;
  }
}
</style>